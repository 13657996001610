import light from '@/theme/light';
import { nFormatter, roundOffFilter } from '@/util/format';

export function bar(res) {
	const option = {
		color: res.color || light.color,
		title: {
			text: res.title,
			subtext: res.subtext || '',
			textStyle: {
				color: '#FFF',
			},
		},
		grid: {
			// left: '14%',
			bottom: '7%'
		},
		toolbox: {
			show: false,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar',
					},
					type: ['line', 'bar'] // 'stack'
				},
				dataZoom: {
					yAxisIndex: 'none',
				},
				restore: {},
			},
		},
		tooltip: {
			axisPointer: {
				type: 'shadow',
			},
			backgroundColor: '#FFF',
			confine: true,
			formatter: params => {
				return `<span style='color:#333';>${params[0].name.toString().split('_', 1)}</span> 
			  <span style='color:#6C6C6C';>${res.tooltip[params[0].name.toString().split('_', 1)]}</span><br/>`;
			},
			trigger: 'axis',
			triggerOn: 'mousemove', // 'click'
			extraCssText: 'z-index:20',
		},
		xAxis: {
			name: '',
			type: 'value',
			silent: false,
			axisTick: { show: false },
			axisLine: {
				onZero: true,
				lineStyle: {
					color: 'white',
				},
			},
			splitLine: { show: false },
			splitArea: { show: false },
			axisLabel: {
				color: '#FFF',
				formatter: value => {
					let yAxisData;
					if (value >= 0) {
						yAxisData = `${nFormatter(value, 1)}`;
					} else {
						yAxisData = `-${nFormatter(Math.abs(value, 1))}`;
					}
					return yAxisData;
				}
			},
			data: [],
		},
		yAxis: [{
			name: '',
			type: 'category',
			zlevel: 1,
			axisTick: { show: false },
			splitArea: { show: false },
			axisLine: { lineStyle: { color: '#626567' } },
			splitLine: { show: false, lineStyle: { color: '#37474F' } },
			axisLabel: {
				show: true,
				inside: true,
				color: '#FFF',
				formatter: value => {
					const arr = value.split('_');
					if(arr[2]) return `{title|${arr[0]}} {amt|${arr[1]}} {percent|${arr[2]}}`;
					return `{title|${arr[0]}} {amt|${arr[1]}}`;
				},
				rich: {
					title: {
						color: '#FFF',
						fontWeight: 900,
						textShadow: '0.25px 0.25px 0.5px #FFF'
					},
					amt: {
						color: '#ffc964',
						fontWeight: 900,
						textShadow: '0.25px 0.25px 0.5px #c8e6c9'
					},
					percent: {
						color: '#FFF'
					}
				}
			},
			data: res.yAxis,
		}],
		series: {
			name: '',
			type: 'bar',
			label: {},
			data: res.series,
		},
	};
	if(res.yAxis.length > 10) {
		option.grid.right = '15%';
		option.dataZoom = [
			{ show: true, yAxisIndex: 0, filterMode: 'empty', textStyle: { color: 'none' }, start: 80, end: 100,
				type: 'slider', showDetail: false, showDataShadow: false }
		];
		if(res.yAxis.length > 25) option.dataZoom[0].start = 90;
		if(res.yAxis.length > 35) option.dataZoom[0].start = 95;
		if(res.yAxis.length > 200) option.dataZoom[0].start = 96;
		if(res.yAxis.length > 500) option.dataZoom[0].start = 97;
		if(res.yAxis.length > 1000) option.dataZoom[0].start = 99.5;
		if(res.yAxis.length > 2000) option.dataZoom[0].start = 99.7;
	}
	return option;
}
export function timeIntervalBar(res) {
	const option = {
		color: res.color || light.color,
		title: {
			text: res.title,
			subtext: res.subtext || '',
			textStyle: {
				color: '#FFF',
			},
		},
		grid: {
			// left: '14%',
			bottom: '7%'
		},
		toolbox: {
			show: false,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar',
					},
					type: ['line', 'bar'] // 'stack'
				},
			},
		},
		tooltip: {
			show: true,
			axisPointer: {
				type: 'shadow',
			},
			backgroundColor: '#FFF',
			confine: true,
			formatter: params =>
				`<span style='color:#333';>${params[0].name.toString().split('_', 1)}</span> 
			  <span style='color:#6C6C6C';>${res.tooltip[params[0].name.toString().split('_', 1)]}</span><br/>`,
			trigger: 'axis',
			triggerOn: 'mousemove',
			extraCssText: 'z-index:8',
		},
		xAxis: {
			name: '',
			type: 'value',
			silent: false,
			axisTick: { show: false },
			axisLine: {
				onZero: true,
				lineStyle: {
					color: 'white',
				},
			},
			splitLine: { show: false },
			splitArea: { show: false },
			axisLabel: {
				color: '#FFF',
				formatter: value => {
					let yAxisData;
					if (value >= 0) {
						yAxisData = `${nFormatter(value, 1)}`;
					} else {
						yAxisData = `-${nFormatter(Math.abs(value, 1))}`;
					}
					return yAxisData;
				}
			},
			data: [],
		},
		yAxis: [{
			name: '',
			type: 'category',
			zlevel: 1,
			axisTick: { show: false },
			splitArea: { show: false },
			axisLine: { lineStyle: { color: '#626567' } },
			splitLine: { show: false, lineStyle: { color: '#37474F' } },
			axisLabel: {
				show: true,
				inside: true,
				color: '#FFF',
				formatter: value => {
					const arr = value.split('_');
					if(arr[2]) return `{title|${arr[0]}} {amt|${arr[1]}} {percent|${arr[2]}}`;
					return `{title|${arr[0]}} {amt|${arr[1]}}`;
				},
				rich: {
					title: {
						color: '#FFF',
						fontWeight: 900,
						textShadow: '0.25px 0.25px 0.5px #FFF'
					},
					amt: {
						color: '#ffc964',
						fontWeight: 900,
						textShadow: '0.25px 0.25px 0.5px #c8e6c9'
					},
					percent: {
						color: '#FFF'
					}
				}
			},
			data: res.yAxis,
		}],
		series: {
			name: '',
			type: 'bar',
			label: {},
			data: res.series,
		},
	};
	return option;
}
export function heatMap(){
	const option = {
		color: light.color,
		title: {
			text: '',
			subtext: '',
			sublink: '',
			textStyle: {
				color: '#fff',
			},
		},
		grid: {
			left: '12%'
		},
		tooltip: {
			position: 'top',
			textStyle: {
				color: '#000',
			},
			formatter: '',
		},
		animation: false,
		xAxis: {
			type: 'category',
			position: 'top',
			data: [],
			axisTick: { show: false },
			axisLine: { onZero: false, lineStyle: { color: '#fff' } },
			splitLine: { show: false },
			splitArea: {
				show: true,
			},
			axisLabel: {
				color: '#FFF',
			},
		},
		yAxis: {
			type: 'category',
			data: [],
			axisTick: { show: false },
			axisLine: { onZero: false, lineStyle: { color: '#fff' } },
			splitArea: {
				show: true,
			},
			axisLabel: {
				interval: 0,
				color: '#FFF',
			},
		},
		visualMap: {
			show: true,
			min: 0,
			max: 10,
			calculable: false,
			color: [],
			orient: 'horizontal',
			left: 'center',
			textStyle: {
				color: '#fff',
			},
		},
		series:[
			{
				name: '',
				type: 'heatmap',
				data: [],
				label: {
					show: false,
				},
				emphasis: {
					shadowBlur: 10,
					shadowColor: 'rgba(0, 0, 0, 0.5)',
				},
			}
		]
	};
	return option;
}
export function pie() {
	const option = {
		color: light.color,
		title: {
			text: '',
			subtext: '',
			// x: 'center',
			textStyle: {
				color: '#FFF',
			},
			subtextStyle: {
				color: '#FFF',
			},
		},
		grid: { left: '3%' },
		graphic: {
			type: 'text',
			left: 'center',
			top: 'center',
			style: {
				text: '',
				fontSize: 14,
				textAlign: 'center',
				fill: '#fff',
			}
		},
		tooltip: {
			confine: true,
			textStyle: {
				color: '#000',
			},
			trigger: 'item',
			formatter: params => `${params.marker}${params.name}: ${roundOffFilter(params.value)}`,
			extraCssText: 'z-index:8',
		},
		legend: {
			top: 'bottom',
			textStyle: { 
				color: '#FFF' 
			},
			data: ''
		},
		series: [
			{
				name: '',
				type: 'pie',
				radius: ['50%', '70%'],
				avoidLabelOverlap: false,
				label: {
					show: false,
				},
				labelLine: {
					show: false,
				},
				data: [],
			},
		],
	};
	return option;
}
