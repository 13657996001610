const dateType = {
	BRIEF_DATE: 'MM/DD',
	BRIEF_HOUR: 'DD/HH',
	BRIEF_MONTH: 'YYYY/MM',
	BRIEF_LOCAL: 'YYYY/MM/DD',
	BRIEF_LOCAL_SECONDS: 'YYYY/MM/DD HH:mm:ss',
	DATETIME_LOCAL: 'YYYY-MM-DDTHH:mm',
	DATETIME_LOCAL_SECONDS: 'YYYY-MM-DDTHH:mm:ss',
	DATETIME_LOCAL_MS: 'YYYY-MM-DDTHH:mm:ss.SSS',
	DATE: 'YYYY-MM-DD',
	TIME: 'HH:mm',
	TIME_SECONDS: 'HH:mm:ss',
	TIME_MS: 'HH:mm:ss.SSS',
	WEEK: 'YYYY-[W]WW',
	MONTH: 'YYYY-MM'
};
export default dateType;