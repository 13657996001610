<template lang='pug'>
v-row
	v-col.chartWrap.mixChart(cols='12', v-if='Object.keys(firstOption).length !== 0 && flag === 0')
		v-col#netWinSelectBox.dataSelectBoxNoTop
			TypePicker(:typeStatus='type', @emitUpdateChartsType='updateData')
		v-chart.chartMoveUpM(:option='firstOption', ref='firstOption', autoresize)
	v-col.chartWrap.mixChart(cols='12', v-if='Object.keys(secondOption).length !== 0 && flag === 1')
		v-col#netWinSelectBox.dataSelectBoxNoTop
			TypePicker(:typeStatus='type', @emitUpdateChartsType='updateData')
		v-chart.chartMoveUpM(:option='secondOption', ref='secondOption', autoresize)
	v-col.chartWrap.mixChart(cols='12', v-if='Object.keys(thirdOption).length !== 0 && flag === 2')
		v-col#netWinSelectBox.dataSelectBoxNoTop
			TypePicker(:typeStatus='type', @emitUpdateChartsType='updateData')
		v-chart.chartMoveUpM(:option='thirdOption', ref='thirdOption', autoresize)
</template>

<script>
import ECharts from 'vue-echarts';
import TypePicker from '@/components/DataPicker/TypePicker';

export default {
	name: 'TripleCharts',
	props: ['firstOption', 'secondOption', 'thirdOption'],
	components: {
		'v-chart': ECharts,
		TypePicker,
	},
	creat() {},
	data() {
		return {
			flag: 0,
			strArray: [
				this.$t('common.basicLine'),
				this.$t('common.stackedArea'),
				this.$t('common.stackedColumn')
			],
			type: this.$t('common.basicLine'),
			percentSwitchShow: true,
			percentSwitch: false,
		};
	},
	methods: {
		clearChart(title) {
			if (title === this.$t('common.basicLine')) {
				// console.log('firstOption1', this.$refs.firstOption);
				// console.log('secondOption', this.$refs.secondOption);
				// console.log('thirdOption', this.$refs.thirdOption);
				if(this.$refs.firstOption) this.$refs.firstOption.clear(); //
				// this.$refs.secondOption.clear();
				// this.$refs.thirdOption.clear();
			}
			if (title === this.$t('common.stackedArea')) {
				// this.$refs.firstOption.clear();
				if(this.$refs.secondOption) this.$refs.secondOption.clear(); //
				// this.$refs.thirdOption.clear();
			}
			if (title === this.$t('common.stackedColumn')) {
				// this.$refs.firstOption.clear();
				// this.$refs.secondOption.clear();
				if(this.$refs.thirdOption) this.$refs.thirdOption.clear(); //
			}
		},
		async updateData(val) {
			this.type = val;
			this.flag = this.strArray.indexOf(val);
			this.clearChart(val);
		},
	},
	watch: {},
};
</script>