import _ from 'lodash';
import moment from 'moment';
import repla from '@/util/dataFilter';
import fetch from '@/util/fetch';
import i18n from '@/locale';
import iter_all from '@/util/format';
import statusCode from '@/assets/constant/statusCode';
import promoType from '@/assets/constant/promoType';
import { number } from 'echarts/core';

//取得 promotion compare
export async function getPC(reqData) {
	const result = await fetch({
		url: '/cars/ocms/house/promo/compare',
		method: 'post',
		data: {
			reqData,
		},
	});

	if(result.status != statusCode.STATUS_OK)
		return;

	const res = result.result.res;
	iter_all(res.ctx_promo_compare);
	for(const i of res.ctx_promo_profile) {
		i.promo_type_name = promoType[i.dim_promo_type];
		i.promo_status = (i.promo_status) ? 'Enabled': 'Disabled';
	}
	return result;
}
// 取得 entire promotion
export async function getPE(reqData) {
	const result = await fetch({
		url: '/cars/ocms/house/promo/entire',
		method: 'post',
		data: {
			reqData,
		},
	});
	if (result.status === statusCode.STATUS_OK) {
		const res = result.result.res;
		// const res = await repla(tmp);
		res.ctx_promo_id_recap = [];
		res.ctx_promo_id_termly = [];
		res.ctx_promo_type_recap = [];
		res.ctx_promo_type_termly = [];
		// res.ctx_promo_termly_org = [];
		res.ctx_promo_termly_sum = [];
		res.ctx_promo_termly_all = [];
		iter_all(res.ctx_basic_recap);
		iter_all(res.ctx_basic_termly, 'YYYY-MM-DD');

		for (const i of res.ctx_promo_recap_early) {
			i.avg_bonus_amt = parseFloat(i.avg_bonus_amt);
			i.avg_in_promo_bet = parseFloat(i.avg_in_promo_bet);
			i.ttl_bonus_amt = parseFloat(i.ttl_bonus_amt);
			i.ttl_in_promo_bet = parseFloat(i.ttl_in_promo_bet);
			if (typeof(i.dim_promo_type) === 'number' && typeof(i.dim_promo_id) === 'number') {
				i.promo_type_name = promoType[i.dim_promo_type];
				// res.ctx_promo_id_recap_early.push(i);
			} else {
				if(!_.isNull(i.dim_promo_type)) {
					if (i.dim_promo_type === 0) {
						res.ctx_basic_recap_early[0].avg_type_0_bonus_amt = i.avg_bonus_amt;
						res.ctx_basic_recap_early[0].ttl_type_0_bonus_amt = i.ttl_bonus_amt;
					}
					if (i.dim_promo_type === 1) {
						res.ctx_basic_recap_early[0].avg_type_1_bonus_amt = i.avg_bonus_amt;
						res.ctx_basic_recap_early[0].ttl_type_1_bonus_amt = i.ttl_bonus_amt;
					}
					if (i.dim_promo_type === 2) {
						res.ctx_basic_recap_early[0].avg_type_2_bonus_amt = i.avg_bonus_amt;
						res.ctx_basic_recap_early[0].ttl_type_2_bonus_amt = i.ttl_bonus_amt;
					}
					if (i.dim_promo_type === 3) {
						res.ctx_basic_recap_early[0].avg_type_3_bonus_amt = i.avg_bonus_amt;
						res.ctx_basic_recap_early[0].ttl_type_3_bonus_amt = i.ttl_bonus_amt;
					}
					i.promo_type_name = promoType[i.dim_promo_type];
					// res.ctx_promo_type_recap_early.push(i);
				}
			}
		}
		for (const i of res.ctx_promo_recap) {
			i.avg_bonus_amt = parseFloat(i.avg_bonus_amt);
			i.avg_in_promo_bet = parseFloat(i.avg_in_promo_bet);
			i.ttl_bonus_amt = parseFloat(i.ttl_bonus_amt);
			i.ttl_in_promo_bet = parseFloat(i.ttl_in_promo_bet);
			if (typeof(i.dim_promo_type) === 'number' && typeof(i.dim_promo_id) === 'number') {
				i.promo_type_name = promoType[i.dim_promo_type];
				res.ctx_promo_id_recap.push(i);
			} else {
				if(!_.isNull(i.dim_promo_type)) {
					if (i.dim_promo_type === 0) {
						res.ctx_basic_recap[0].avg_type_0_bonus_amt = i.avg_bonus_amt;
						res.ctx_basic_recap[0].ttl_type_0_bonus_amt = i.ttl_bonus_amt;
					}
					if (i.dim_promo_type === 1) {
						res.ctx_basic_recap[0].avg_type_1_bonus_amt = i.avg_bonus_amt;
						res.ctx_basic_recap[0].ttl_type_1_bonus_amt = i.ttl_bonus_amt;
					}
					if (i.dim_promo_type === 2) {
						res.ctx_basic_recap[0].avg_type_2_bonus_amt = i.avg_bonus_amt;
						res.ctx_basic_recap[0].ttl_type_2_bonus_amt = i.ttl_bonus_amt;
					}
					if (i.dim_promo_type === 3) {
						res.ctx_basic_recap[0].avg_type_3_bonus_amt = i.avg_bonus_amt;
						res.ctx_basic_recap[0].ttl_type_3_bonus_amt = i.ttl_bonus_amt;
					}
					i.promo_type_name = promoType[i.dim_promo_type];
					res.ctx_promo_type_recap.push(i);
				}
			}
		}
		for(const i of res.ctx_promo_termly) {
			if (i.dim_ptt) {
				i.dim_ptt = (i.dim_ptt) ? moment.utc(i.dim_ptt.value).format('YYYY-MM-DD') : null;
				if(typeof(i.dim_promo_type) === 'number' && typeof(i.dim_promo_id) === 'number') {
					i.promo_type_name = promoType[i.dim_promo_type];
					res.ctx_promo_id_termly.push(i);
				} else {
					i.promo_type_name = promoType[i.dim_promo_type];
					res.ctx_promo_termly_sum.push(i);
				}
			} else {
				res.ctx_promo_termly_all.push(i);
			}
		}
	}
	return result;
}