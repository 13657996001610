import light from '@/theme/light';
import { nFormatter, roundDecimal } from '@/util/format';

export function summaryBar(res) {
	const option = {
		color: res.color || light.color,
		title: {
			text: res.title,
			subtext: res.subtext,
			textStyle: {
				color: '#FFF',
			},
			subtextStyle: {
				color: '#FFF',
			}
		},
		grid: {
			// left: '14%',
			bottom: '5%'
		},
		toolbox: {
			show: false,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar',
					},
					type: ['line', 'bar'] // 'stack'
				},
			},
		},
		tooltip: {
			axisPointer: {
				type: 'shadow',
			},
			backgroundColor: '#FFF',
			confine: true,
			formatter: params =>
				`<span style='color:#333';>${params[0].name.toString().split('_', 1)}</span> 
			<span style='color:#6C6C6C';>Rank: ${option.series.data.length - params[0].dataIndex}</span><br/>`,
			trigger: 'axis',
			triggerOn: 'mousemove',
			extraCssText: 'z-index:8',
		},
		xAxis: {
			show: true,
			name: '',
			type: 'value',
			silent: false,
			axisTick: { show: false },
			axisLine: {
				onZero: true,
				lineStyle: {
					color: 'white',
				},
			},
			splitLine: { show: false },
			splitArea: { show: false },
			axisLabel: {
				color: '#FFF',
				formatter: value => {
					let yAxisData;
					if (value >= 0) {
						yAxisData = `${nFormatter(value, 1)}`;
					} else {
						yAxisData = `-${nFormatter(Math.abs(value, 1))}`;
					}
					return yAxisData;
				}
			},
			data: [],
		},
		yAxis: [{
			name: '',
			type: 'category',
			zlevel: 1,
			axisTick: { show: false },
			splitArea: { show: false },
			axisLine: { lineStyle: { color: '#626567' } },
			splitLine: { show: false, lineStyle: { color: '#37474F' } },
			axisLabel: {
				show: true,
				inside: true,
				color: '#FFF',
				formatter: value => {
					const arr = value.split('_');
					if(arr[2]) return `{title|${arr[0]}} {amt|${arr[1]}} {percent|${arr[2]}}`;
					return `{title|${arr[0]}} {amt|${arr[1]}}`;
				},
				rich: {
					title: {
						color: '#FFF',
						fontWeight: 900,
						textShadow: '0.25px 0.25px 0.5px #FFF'
					},
					amt: {
						color: '#ffc964',
						fontWeight: 900,
						textShadow: '0.25px 0.25px 0.5px #c8e6c9'
					},
					percent: {
						color: '#FFF'
					}
				}
			},
			data: res.yAxis,
		}],
		series: {
			name: '',
			type: 'bar',
			label: {},
			data: res.series,
		},
	};
	if(res.yAxis.length > 7) {
		const len = roundDecimal(100 - 8 / res.yAxis.length * 100, 2);
		option.grid.right = '15%';
		option.dataZoom = [
			{ show: true, yAxisIndex: 0, filterMode: 'empty', textStyle: { color: 'none' }, start: len, end: 100,
				type: 'slider', showDetail: false, showDataShadow: false }
		];
		// if(res.yAxis.length > 35) option.dataZoom[0].start = 95;
		// if(res.yAxis.length > 500) option.dataZoom[0].start = 97;
		// if(res.yAxis.length > 1000) option.dataZoom[0].start = 99.5;
		// if(res.yAxis.length > 2000) option.dataZoom[0].start = 99.7;
	} else {
		option.dataZoom = [{show: false}];
	}
	return option;
}
