import dateType from '@/assets/constant/dateType';
import light from '@/theme/light';
import moment from 'moment';
import {nFormatter, rdPercentOff, roundOffFilter} from '@/util/format';
import i18n from '@/locale';

let export_root = {};

export_root.gen_bar_percent_opt = opt_append => {
  let opt_default = {
    color: light.color,
    title: {
      // text: res.title || '',
      // subtext: res.subtext || '',
      textStyle: {color: '#FFF'},
      subtextStyle: {
        align: 'center',
        color: '#FFF',
        fontSize: 16,
      }
    },
    // grid: {top: '22%', bottom: '24%', left: '17%'},
    legend: {
      left: 'center',
      top: 'bottom',
      textStyle: {color: '#FFF'},
    },
    toolbox: {
      show: false,
      feature: {
        magicType: {
          show: true,
          title: {
            line: 'Line',
            bar: 'Bar',
          },
          type: ['line', 'bar'] // 'stack'
        },
      },
    },
    tooltip: {
      axisPointer: {type: 'shadow'},
      textStyle: {color: 'black'},
      confine: true,
      formatter: '',
      trigger: 'axis',
      triggerOn: 'mousemove',
      extraCssText: 'z-index:8',
    },
    xAxis: {
      type: 'category',
      axisTick: {show: false},
      axisLine: {lineStyle: {color: 'white'}},
      axisLabel: {
        // interval: 0,
        color: '#FFF',
        // formatter: value => {
        //   return moment(value).format(dateType.BRIEF_DATE);
        // }
      },
    },
    yAxis: [{
      type: 'value',
      min: 0,
      max: 1,
      axisTick: {show: false},
      axisLine: {lineStyle: {color: 'white'}},
      axisLabel: {
        formatter: value => `${nFormatter(value * 100, 1)}%`,
        color: '#FFF',
        fontSize: 11,
      },
      splitLine: {lineStyle: {color: '#37474F'}},
    }],
  };
  return _.cloneDeep(_.merge(opt_default, opt_append));
}

export_root.gen_line_opt = opt_append => {
  let opt_default = {
    color: light.color,
    title: {
      // text: params.title || '',
      // subtext: params.subtext || '',
      x: 'left',
      textStyle: {
        color: '#FFF',
        fontSize: 20,
      },
      subtextStyle: {color: '#FFF'}
    },
    legend: {
      left: 'center',
      top: 'bottom',
      textStyle: {color: '#FFF'},
      // data: params.legend || [],
    },
    toolbox: {
      show: false,
      showTitle: false,
      itemSize: 15,
      feature: {
        magicType: {
          show: true,
          title: {
            line: 'Line',
            bar: 'Bar',
          },
          type: ['line', 'bar']
        },
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {type: 'line'},
      backgroundColor: '#FFF',
      confine: true,
      textStyle: {color: 'black'},
      extraCssText: 'z-index:8',
    },
    xAxis: {
      type: 'category',
      axisTick: {show: false},
      axisLine: {lineStyle: {color: 'white'}},
      axisLabel: {
        color: '#FFF',
        // formatter: value => {
        //   return moment(value).format(dateType.BRIEF_DATE);
        // },
      },
      // data: params.xAxis || [],
    },
    yAxis: {
      type: 'value',
      axisTick: {show: false},
      axisLine: {lineStyle: {color: 'white'}},
      axisLabel: {
        color: '#FFF',
        formatter: value => `${nFormatter(value, 1)}`,
      },
      splitLine: {lineStyle: {color: '#37474F'}},
    },
    // series: params.series || [],
  };
  return _.cloneDeep(_.merge(opt_default, opt_append, customizer));
}

function customizer(target, source) {
  if (source === undefined) {
    return target;
  }
}

export_root.get_pie_opt = opt_append => {
  let opt_default = {
    color: light.color,
    title: {
      textStyle: {
        color: '#FFF',
        fontSize: 20,
      },
      subtextStyle: {color: '#FFF'},
    },
    grid: {left: '3%'},
    graphic: {
      type: 'text',
      left: 'center',
      top: 'center',
      style: {
        // text: '',
        fontSize: 14,
        textAlign: 'center',
        fill: '#fff',
      },
    },
    tooltip: {
      confine: true,
      textStyle: {color: '#000'},
      trigger: 'item',
      formatter: params => `${params.marker}${params.name}: ${roundOffFilter(params.value)}`,
      extraCssText: 'z-index:8',
    },
    legend: {
      top: 'bottom',
      textStyle: {color: '#FFF'},
    },
    // xAxis: {type: 'category'},
    // yAxis: {},
    series: [
      {
        // name: '',
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        label: {show: false},
        labelLine: {show: false},
      },
    ],
  };
  return _.cloneDeep(_.merge(opt_default, opt_append));
}

export_root.build_card = (col_name, ctx_data_main, ctx_data_prev, show_opt, card_opt) => {
  let card_opt_default = _.merge({
    is_negative_growth: false,
    note: 'value',
  }, card_opt);

  let show_opt_default = _.merge({}, show_opt);
  let title_prefix = show_opt_default.title_prefix ? i18n.t(`modifier.${show_opt_default.title_prefix}`) : '';

  if(col_name.startsWith('avg_')){
    const col_match = col_name.replace('avg_', '');
    return _.merge(card_opt_default, {
      mainTitle: title_prefix + i18n.t('modifier.prefix_avg') + i18n.t(`metric.${col_match}`),
      subTitle: i18n.t('charts.earlyNumber'),
      mainData: ctx_data_main[`avg_${col_match}`] || 0,
      subData: ctx_data_prev[`avg_${col_match}`] || 0,
      tooltip: true,
      toolMainTitle: title_prefix + i18n.t('modifier.prefix_ttl') + i18n.t(`metric.${col_match}`),
      toolSubTitle: i18n.t('charts.thisTerm'),
      toolMainData: ctx_data_main[`ttl_${col_match}`] || 0,
      toolSubData: ctx_data_prev[`ttl_${col_match}`] || 0,
    });
  }else{
    let col_match = col_name;
    let modifier_prefix = '';
    if(col_name.startsWith('pupd_')){
      col_match = col_name.replace('pupd_', '');
      modifier_prefix = i18n.t('modifier.prefix_pupd');
    }
    return _.merge(card_opt_default, {
      mainTitle: title_prefix + modifier_prefix + i18n.t(`metric.${col_match}`),
      subTitle: i18n.t('charts.earlyNumber'),
      mainData: ctx_data_main[col_name] || 0,
      subData: ctx_data_prev[col_name] || 0,
    });
  }
}

export_root.build_triple_chart = (chart_opt_root, col_list) => {
  // const xaxis_label_formatter = '{yyyy}-{MM}-{dd}';
  const xaxis_label_formatter = '{MM}/{dd}';

  if(chart_opt_root.opt_line){
    chart_opt_root.opt_line = export_root.gen_line_opt({
      legend: {data: col_list.map(e_2 => e_2.col_show)},
      xAxis: {
        type: 'time',
        axisLabel: {formatter: xaxis_label_formatter},
      },
      dataset: col_list.map(e_2 => {
        return {
          dimensions: ['dim_ptt', e_2.col_name],
          source: e_2.col_data,
        };
      }),
      series: col_list.map((e_2, ix_2) => {
        return {
          type: 'line',
          name: e_2.col_show,
          color: e_2.color,
          tooltip: {valueFormatter: value => roundOffFilter(value)},
          datasetIndex: ix_2,
        };
      }),
    });
  }

  if(chart_opt_root.opt_area){
    chart_opt_root.opt_area = export_root.gen_line_opt({
      legend: {data: col_list.map(e_2 => e_2.col_show)},
      xAxis: {
        type: 'time',
        axisLabel: {formatter: xaxis_label_formatter},
        boundaryGap: false,
      },
      dataset: col_list.map(e_2 => {
        return {
          dimensions: ['dim_ptt', e_2.col_name],
          source: e_2.col_data,
        };
      }),
      series: col_list.map((e_2, ix_2) => {
        return {
          type: 'line',
          stack: 'all',
          areaStyle: {},
          name: e_2.col_show,
          color: e_2.color,
          tooltip: {valueFormatter: value => roundOffFilter(value)},
          datasetIndex: ix_2,
        };
      }),
    });
  }

  if(chart_opt_root.opt_stack){
    chart_opt_root.opt_stack = export_root.gen_bar_percent_opt({
      legend: {data: col_list.map(e_2 => e_2.col_show)},
      xAxis: {
        type: 'time',
        axisLabel: {formatter: xaxis_label_formatter},
        boundaryGap: false,
      },
      dataset: col_list.map(e_2 => {
        return {
          dimensions: ['dim_ptt', `${e_2.col_name}_ratio`],
          source: e_2.col_data,
        };
      }),
      series: col_list.map((e_2, ix_2) => {
        return {
          type: 'bar',
          stack: 'all',
          name: e_2.col_show,
          color: e_2.color,
          tooltip: {valueFormatter: value => `${(value * 100).toFixed(1)}%`},
          datasetIndex: ix_2,
        };
      }),
    });
  }
}

export_root.build_table_chart = (chart_opt_root, ctx_data, col_list, key_col_name, tabulator_table_opt) => {
  let table_data = [];
  for(const e_1 of ctx_data){
    const row_data = {};
    row_data[key_col_name] = e_1[key_col_name];
    col_list.forEach(e_2 => {
      row_data[e_2.col_name] = e_1[e_2.col_name];
    });
    table_data.push(row_data);
  }

  const columns = col_list.map(e_1 => {
    let opt_init_text = {
      title: i18n.t(`metric.${e_1.col_show}`),
      field: e_1.col_name,
      headerHozAlign: 'center',
      hozAlign: 'center',
      frozen: true,
    };

    let title_prefix = '';
    if(e_1.col_name.includes('avg_')) title_prefix = i18n.t('modifier.prefix_avg');
    if(e_1.col_name.includes('pupd_')) title_prefix = i18n.t('modifier.prefix_pupd');

    let opt_init_bar = {
      title: title_prefix + i18n.t(`metric.${e_1.col_show}`),
      field: e_1.col_name,
      hozAlign: 'left',
      formatter: "progress",
      formatterParams: {
        max: Math.max(...table_data.map(e_2 => e_2[e_1.col_name])),
        legend: value => e_1.is_ratio ? rdPercentOff(value) : roundOffFilter(value),
        legendColor: '#FFF',
      },
    };

    if(e_1.tabulator_column_opt_text)
      return _.cloneDeep(_.merge(opt_init_text, e_1.tabulator_column_opt_text));
    if(e_1.tabulator_column_opt_bar)
      return _.cloneDeep(_.merge(opt_init_bar, e_1.tabulator_column_opt_bar));
  });

  chart_opt_root.table_data = table_data;
  chart_opt_root.table_opt = _.cloneDeep(_.merge(tabulator_table_opt, {
    layout: 'fitColumns',
    pagination: 'local',
    paginationSize: 20,
    movableColumns: true,
    tooltipGenerationMode: 'hover',
    columns: columns,
    tooltips: cell => {
      const cell_data = cell.getData();
      const row_data = ctx_data.filter(e_1 => e_1[key_col_name] === cell_data[key_col_name])[0];
      for(const e_1 of col_list){
        if(!e_1.col_name.startsWith('avg_') || cell.getField() != e_1.col_name)
          continue;

        const fn_formatter = (e_1.is_ratio ? rdPercentOff : roundOffFilter);
        const title_1 = i18n.t('modifier.prefix_ttl') + i18n.t(`metric.${e_1.col_show}`);
        const title_2 = i18n.t('modifier.prefix_avg') + i18n.t(`metric.${e_1.col_show}`);
        return `${title_1}: ${fn_formatter(row_data[e_1.col_name.replace('avg_', 'ttl_')])}\n${title_2}: ${fn_formatter(cell.getValue())}`;
      };
    }
  }));
}

export default export_root;
