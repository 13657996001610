<template lang='pug'>
v-row
	v-col.chartWrap(cols='12' v-if='Object.keys(firstOption).length !== 0 && percentSwitch === false')
		v-col.switchBoxForEightPix
			span(v-show='percentSwitchShow')
			v-switch(:ripple='false', v-model='percentSwitch', v-show='percentSwitchShow')
		v-chart.chartMoveUp(:option='firstOption', ref='firstOption', autoresize)
	v-col.chartWrap(cols='12', v-if='Object.keys(secondOption).length !== 0 && percentSwitch === true')
		v-col.switchBoxForEightPix
			span(v-show='percentSwitchShow')
			v-switch(:ripple='false', v-model='percentSwitch', v-show='percentSwitchShow')
		v-chart.chartMoveUp(:option='secondOption', ref='secondOption', autoresize)
</template>

<script>
import ECharts from 'vue-echarts';

export default {
	name: 'ChartsSwitch',
	props: [
		'firstOption',
		'secondOption'
	],
	components: {
		'v-chart': ECharts,
	},
	creat() {
		// console.log('firstOption', this.firstOption);
		// console.log('secondOption', this.secondOption);
	},
	data() {
		return {
			percentSwitchShow: true,
			percentSwitch: false,
		};
	},
	methods: {},
	watch: {
		// firstOption: {
		// 	handler: function (val) {
		// 		if (val) {
		// 			console.log('firstOption', val);
		// 		}
		// 	},
		// 	deep: true,
		// 	immediate: true,
		// },
		// secondOption:  {
		// 	handler: function (val) {
		// 		if (val) {
		// 			console.log('secondOption', val);
		// 		}
		// 	},
		// 	deep: true,
		// 	immediate: true,
		// },
		percentSwitch: function () {
			if(!this.percentSwitch) {
				this.$refs.secondOption.clear();
			} else {
				this.$refs.firstOption.clear();
			}
		},
	},
}
</script>