import i18n from '@/locale';
import statusCode from '@/assets/constant/statusCode';

export default function statusMsg(status){
	let msg = '';
	if (status === statusCode.STATUS_NO_MATCH_DATA) msg = i18n.t('error.noMatchData');
	if (status === statusCode.STATUS_NOT_AUTHORIZED) msg = i18n.t('error.userNotAuthorize');
	if (status === statusCode.STATUS_USER_NOT_EXISTED) msg = i18n.t('error.userNotExisted');
	return msg;
}
