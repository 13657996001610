import dateType from '@/assets/constant/dateType';
import light from '@/theme/light';
import moment from 'moment';
import { nFormatter, roundOffFilter } from '@/util/format';

export function bar(res) {
	const option = {
		color: res.color || light.color,
		title: {
			text: res.title || '',
			subtext: res.subtext || '',
			textStyle: {
				color: '#FFF',
				// fontSize: 20
			},
			subtextStyle: {
				align: 'center',
				color: '#FFF',
				fontSize: 16
			}
		},
		grid: {
			left: '14%'
		},
		legend: {
			left: 'center',
			top: 'bottom',
			textStyle: { color: '#FFF' },
			data: res.legend || [],
		},
		toolbox: {
			show: false,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar',
					},
					type: ['line', 'bar'] // 'stack'
				},
			},
		},
		tooltip: {
			show: true,
			axisPointer: {
				type: 'shadow',
			},
			textStyle: {
				color: 'black',
			},
			confine: false,
			formatter: '',
			trigger: 'axis',
			triggerOn: 'mousemove',
			extraCssText: 'z-index:8',
		},
		xAxis: {
			name: '',
			// type: 'category',
			type: 'value',
			silent: false,
			axisTick: { show: false },
			axisLine: {
				onZero: true,
				lineStyle: {
					color: 'white',
				},
			},
			splitLine: { show: false },
			splitArea: { show: false },
			axisLabel: {
				color: '#FFF',
				formatter: value => {
					return `${nFormatter(value, 1)}`;
					// return moment(value).format(dateType.BRIEF_DATE);
				}
			},
			// data: res.xAxis || [],
		},
		yAxis: [{
			name: '',
			// type: 'value',
			type: 'category',
			axisTick: { show: false },
			splitArea: { show: false },
			axisLine: { lineStyle: { color: 'white' } },
			splitLine: { show: false, lineStyle: { color: '#37474F' } },
			axisLabel: {
				show: true,
				color: '#FFF',
			},
			data: res.yAxis || [],
		}],
		series: res.series || [],
	};
	return option;
}
export function barPercent(res) {
	const option = {
		color: res.color || light.color,
		title: {
			text: res.title || '',
			subtext: res.subtext || '',
			textStyle: {
				color: '#FFF',
				// fontSize: 20
			},
			subtextStyle: {
				align: 'center',
				color: '#FFF',
				fontSize: 16
			}
		},
		grid: { top: '22%', bottom: '24%', left: '17%' },
		legend: {
			left: 'center',
			top: 'bottom',
			textStyle: { color: '#FFF' },
			data: res.legend || [],
		},
		toolbox: {
			show: false,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar',
					},
					type: ['line', 'bar'] // 'stack'
				},
			},
		},
		tooltip: {
			axisPointer: {
				type: 'shadow',
			},
			textStyle: {
				color: 'black',
			},
			confine: true,
			formatter: '',
			trigger: 'axis',
			triggerOn: 'mousemove',
			extraCssText: 'z-index:8',
		},
		xAxis: {
			type: 'category',
			data: res.xAxis || [],
			axisTick: {
				show: false,
			},
			axisLine: {
				lineStyle: {
					color: 'white',
				},
			},
			axisLabel: {
				// interval: 0,
				color: '#FFF',
				formatter: value => {
					return moment(value).format(dateType.BRIEF_DATE);
				}
			},
		},
		yAxis: [{
			type: 'value',
			min: 0,
			max: 101,
			// min: value => {
			// 	return value.min;
			// },
			// max: value => {
			// 	return value.max;
			// },
			axisTick: {
				show: false,
			},
			axisLine: {
				lineStyle: {
					color: 'white',
				},
			},
			axisLabel: {
				formatter: value => {
					return `${nFormatter(value, 1)}%`;
				},
				color: '#FFF',
				fontSize: 11
			},
			splitLine: { lineStyle: { color: '#37474F' } },
			// data: res.yAxis || [],
		}],
		series: res.series || [],
	};
	return option;
}
export function straightBar(res) {
	const option = {
		color: res.color || light.color,
		title: {
			text: res.title || '',
			subtext: '',
			textStyle: {
				color: '#FFF',
				fontSize: 20
			},
		},
		grid: {
			left: '14%'
		},
		legend: {
			left: 'center',
			top: 'bottom',
			textStyle: { color: '#FFF' },
			data: res.legend || [],
		},
		toolbox: {
			show: false,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar',
					},
					type: ['line', 'bar'] // 'stack'
				},
			},
		},
		tooltip: {
			axisPointer: {
				type: 'shadow',
			},
			textStyle: {
				color: 'black',
			},
			confine: true,
			formatter: params => {
				// console.log('params', params);
				return `${params[1].marker}${params[1].name}: ${roundOffFilter(params[1].value)}`;
			},
			trigger: 'axis',
			triggerOn: 'mousemove',
			extraCssText: 'z-index:8',
		},
		xAxis: {
			name: '',
			type: 'category',
			// type: 'value',
			silent: false,
			axisTick: { show: false },
			axisLine: {
				onZero: true,
				lineStyle: {
					color: 'white',
				},
			},
			splitLine: { show: false },
			splitArea: { show: false },
			axisLabel: {
				color: '#FFF',
				// formatter: value => {
				// 	return `${nFormatter(value, 1)}`;
				// return moment(value).format(dateType.BRIEF_DATE);
				// }
			},
			data: res.xAxis || [],
		},
		yAxis: [{
			name: '',
			type: 'value',
			// type: 'category',
			axisTick: { show: false },
			splitArea: { show: false },
			axisLine: { lineStyle: { color: 'white' } },
			splitLine: { show: false, lineStyle: { color: '#37474F' } },
			axisLabel: {
				show: true,
				color: '#FFF',
				formatter: value => {
					return `${nFormatter(value, 1)}`;
				},
			},
			// data: res.yAxis || [],
		}],
		series: res.series || [],
	};
	if(res.xAxis && res.xAxis.length > 10) {
		option.grid.right = '15%';
		option.dataZoom = [
			{ show: true, xAxisIndex: 0, filterMode: 'empty', textStyle: { color: 'none' }, start: 0, end: 20,
				type: 'slider', showDetail: false, showDataShadow: false }
		];
		if(res.xAxis.length > 25) option.dataZoom[0].end = 10;
		if(res.xAxis.length > 35) option.dataZoom[0].end = 5;
		if(res.xAxis.length > 200) option.dataZoom[0].end = 4;
		if(res.xAxis.length > 500) option.dataZoom[0].end = 3;
		if(res.xAxis.length > 1000) option.dataZoom[0].end = 0.5;
		if(res.xAxis.length > 2000) option.dataZoom[0].end = 0.3;
	}
	return option;
}
export function line(res) {
	const option = {
		color: res.color || light.color,
		title: {
			text: res.title || '',
			subtext: res.subtext || '',
			x: 'left',
			textStyle: {
				color: '#FFF',
				fontSize: 20
			},
			subtextStyle: {
				color: '#FFF',
			}
		},
		legend: {
			left: 'center',
			top: 'bottom',
			textStyle: { color: '#FFF' },
			data: res.legend || [],
		},
		toolbox: {
			show: false,
			showTitle: false,
			itemSize: 15,
			feature: {
				magicType: {
					show: true,
					title: {
						line: 'Line',
						bar: 'Bar',
						// stack: 'Stack'
					},
					// type: ['line', 'bar', 'stack']
					type: ['line', 'bar']
				},
			},
		},
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'line',
			},
			backgroundColor: '#FFF',
			confine: true,
			textStyle: { color: 'black' },
			formatter: '',
			// formatter: params => {
			// 	let str = `${moment(params[0].name).format(dateType.BRIEF_DATE)}<br/>`;
			// 	for (let i of params) {
			// 		str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value, 1)}<br/>`;
			// 	}
			// 	return str;
			// },
			extraCssText: 'z-index:8',
		},
		xAxis: {
			type: 'category',
			axisTick: {
				show: false,
			},
			axisLine: {
				lineStyle: {
					color: 'white',
				},
			},
			axisLabel: {
				// interval: 0,
				color: '#FFF',
				formatter: value => {
					return moment(value).format(dateType.BRIEF_DATE);
				}
			},
			data: res.xAxis || [],
		},
		yAxis: {
			type: 'value',
			axisTick: {
				show: false,
			},
			axisLine: {
				lineStyle: {
					color: 'white',
				},
			},
			axisLabel: {
				color: '#FFF',
				formatter: value => {
					return `${nFormatter(value, 1)}`;
				},
			},
			splitLine: { lineStyle: { color: '#37474F' } },
		},
		series: res.series || [],
		// series: {
		// 	type: 'line',
		// 	label: {
		// 		normal: {
		// 			show: false,
		// 			position: 'top',
		// 		},
		// 	},
		// 	name: '',
		// 	data: [],
		// },
	};
	return option;
}
export function pie(res) {
	const option = {
		color: res.color || light.color,
		title: {
			text: res.title || '',
			subtext: res.subtext || '',
			// x: 'center',
			textStyle: {
				color: '#FFF',
				fontSize: 20
			},
		},
		grid: { left: '3%' },
		tooltip: {
			confine: false,
			trigger: 'item',
			textStyle: { color: '#000' },
			formatter: params => {
				return `${params.marker}${params.name}: ${roundOffFilter(params.value)}`;
			},
			extraCssText: 'z-index:8',
		},
		legend: {
			show: true,
			left: 'center',
			top: 'bottom',
			textStyle: { color: '#FFF' },
			data: res.legend || [],
		},
		series: [
			{
				name: res.name || '',
				type: 'pie',
				radius: ['50%', '70%'],
				avoidLabelOverlap: false,
				label: {
					show: false,
				},
				labelLine: {
					show: false,
				},
				data: res.series || [],
			},
		],
	};
	return option;
}