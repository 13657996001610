<template lang='pug'>
ValidationObserver(v-slot='{ invalid, validated, passes, validate }')
	v-overlay(:value='show', opacity='0.8', z-index='6')
		v-progress-circular(indeterminate, color='primary')
	v-card.filter-wrap
		v-tabs.filter-tab(v-model='tab', color='#fff', hide-slider)
			v-tab {{ $t("charts.everyDay") }}
		v-tabs-items.mt-1.mb-5.filter-wrap(v-model='tab')
			v-tab-item
				v-card.pa-4.py-0.filter-wrap(height='60px')
					v-row
						v-col.px-3(cols='12', sm='8', md='6', lg='3')
							DaliyPicker(:tab='tab', @emitupdateDate='updateDate')
		v-card.pt-0.pa-4.pb-4.filter-wrap(v-resize='onResize')
			v-row.justify-end.exportBtnUpper
				v-col.pa-4(v-if='windowSize.x <= 599')
				v-col.text-right.pa-0(v-if='windowSize.x > 599')
					div(@click='showhelperText')
						ExportBtn(:exportResult='getExportResult', @emitexportData='exportXlsxFile')
						span.ml-auto.mr-7.helperText(:class='{ show: helperShow }') {{ $t("common.helpText") }}
			SingleSelectCurrency(:currencyStatus='currencyStatus', @emitDomainValue='updateDomainValue')
			v-row.justify-center
				v-col(cols='12', sm='8', md='6', lg='3')
					v-btn.searchBtn.defult-btn-color(@click='passes(send)', dark, rounded, style='width: 100%; height: 55px') {{ $t("common.search") }}
			v-card.pa-4.filter-wrap.div-w(v-if='Object.keys(getExportResult).length !== 0')
				v-col.py-0.fixBar.searchDate.justify-center(cols='12', md='6', lg='5', @click='goSearch')
					v-row.py-1
						v-col.ml-5.mr-auto.py-1.px-3(cols='auto')
							span.sub-title {{ searchDates[0] }} ～ {{ searchDates[1] }}
						v-col.mr-2.py-1.px-3(cols='auto')
							v-img.researchImg(max-width='25px', :src='imgSrc')
				v-row
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.promo_count')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.avg_bonus_amt')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.avg_no_promo_bet')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.avg_in_promo_bet')
				v-row
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avg_type_1_bonus_amt')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avg_type_2_bonus_amt')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avg_type_3_bonus_amt')
					v-col.memberKanban(cols='3')
						Kanban.mx-1(:cardData='cardData.avg_type_0_bonus_amt')
				v-row
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.avg_no_promo_gm_users')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.avg_join_users')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.avg_join_count')
					v-col.nopadding(cols='3')
						Kanban.mx-1(:cardData='cardData.avg_done_count')
				v-row
					v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(pieOption1).length !== 0')
						v-chart.chartMoveDown(:option='pieOption1', ref='pieOption1', autoresize)
					TripleCharts(:firstOption='chart_options.gm_users.opt_line' :secondOption='chart_options.gm_users.opt_area' :thirdOption ='chart_options.gm_users.opt_stack')
				v-row
					v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(pieOption2).length !== 0')
						v-chart.chartMoveDown(:option='pieOption2', ref='pieOption2', autoresize)
					TripleCharts(:firstOption='chart_options.apv_bet.opt_line' :secondOption='chart_options.apv_bet.opt_area' :thirdOption ='chart_options.apv_bet.opt_stack')
				v-row
					v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(barOption1).length !== 0')
						v-chart(:option='barOption1', ref='barOption1', autoresize)
					v-col.chartWrap(cols='12', lg='8', v-if='Object.keys(lineOption3).length !== 0')
						v-chart(:option='lineOption3', ref='lineOption3', autoresize)
				v-row
					v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(barOption2).length !== 0')
						v-chart(:option='barOption2', ref='barOption2', autoresize)
					TripleCharts(:firstOption='chart_options.bonus_amt.opt_line' :secondOption='chart_options.bonus_amt.opt_area' :thirdOption ='chart_options.bonus_amt.opt_stack')

				v-row
					v-col.chartWrap(cols='12', lg='4', v-if='Object.keys(pieOption5).length !== 0')
						v-chart(:option='pieOption5', @click='getPromoID' ref='pieOption5', autoresize)
					v-col.chartWrap(cols='12', lg='8', v-if='Object.keys(lineOption6).length !== 0')
						v-chart(:option='lineOption6', ref='lineOption6', autoresize)
</template>
<style scoped>
@media (max-width: 1500px) {
	.dataSelectBox {
		left: 0%;
	}
}
</style>
<script>
import i18n from '@/locale';
import chart_option_helper from '@/assets/echarts/chart_option_helper';
import { bar as norBar, barPercent, line , pie} from '@/assets/echarts/ocmsCommon/house/reg';
import { heatMap, pie as norPie } from '@/assets/echarts/ocmsCommon/customer/single';
import { summaryBar } from '@/assets/echarts/ocmsCommon/customer/entire';
import { errorLogger } from '@/util/logger';
import { exportXlsx } from '@/util/xlsx';
import { getPE } from '@/api/Promotion';
import { mapActions, mapGetters } from 'vuex';
import { TabulatorComponent } from "vue-tabulator";
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import {
	commaFormatter,
	nFormatter,
	trans,
	transData,
	toThousandslsFilter,
	rateOfExchange,
	roundFilter,
	roundOffFilter,
	rdPercent,
	rdPercentOff,
	rdPercentOn,
	roundDecimal,
	roundOff,
	getNaN,
} from '@/util/format';
import ChartsSwitch from '@/components/Charts/ChartsSwitch';
import TripleCharts from '@/components/Charts/TripleCharts';
import { render as trend_switchable_render } from '@/assets/echarts/ocmsCommon/trend_switchable';
import DaliyPicker from '@/components/DatePicker/DaliyPicker_ocms';
import ECharts from 'vue-echarts';
import ExportBtn from '@/components/ExportBtn';
import SingleSelectCurrency from '@/components/SingleSelectCurrency';
import Kanban from '@/components/kanbans/Kanban';
import dateType from '@/assets/constant/dateType';
import statusCode from '@/assets/constant/statusCode';
import promoType from '@/assets/constant/promoType';
import statusMsg from '@/util/http';
import COLORS from '@/theme/color';
import moment from 'moment';
import jsonwebtoken from 'jsonwebtoken';

export default {
	components: {
		'v-chart': ECharts,
		ChartsSwitch,
		TripleCharts,
		DaliyPicker,
		ExportBtn,
		Kanban,
		SingleSelectCurrency,
		'VueTabulator': TabulatorComponent,
		ValidationObserver,
		ValidationProvider,
	},
	data() {
		return {
			windowSize: {
				x: 0,
			},
			dialog: false,
			getExportResult: {},
			helperShow: false,
      		currencyStatus: true,
			show: false,
			tab: null,
			loading: false,
			dates: [],
			searchDates: [],
			dateType: dateType.BRIEF_DATE,
			imgSrc: require('@/assets/image/research.png'),
			recapId: [],
			// topTen: [],
			real: [],
			allTermlyId: [],
			promoDetail: {},
			cardData: {},
			chart_options: {
				gm_users: {opt_line: {}, opt_area: {}, opt_stack: {}},
				apv_bet: {opt_line: {}, opt_area: {}, opt_stack: {}},
				bonus_amt: {opt_line: {}, opt_area: {}, opt_stack: {}},
			},
			barOption1: {},
			barOption2: {},
			barOption3: {},
			barOption4: {},
			barOption5: {},
			lineOption1: {},
			lineOption2: {},
			lineOption3: {},
			lineOption4: {},
			lineOption5: {},
			lineOption6: {},
			pieOption1: {},
			pieOption2: {},
			pieOption3: {},
			pieOption4: {},
			pieOption5: {},
		};
	},
	mounted() {
		this.initData();
	},
	methods: {
		...mapActions(['setDialogShow', 'setStatusDialog', 'setOperatorDefaultRouterTab', 'setOpenAutoSearch"']),
		async send() {
			this.allClean();
			this.show = !this.show;
			this.loading = !this.loading;
			try {
				const reqData = {
					currency: this.currency,
					startDate: this.$moment(this.dates[0]).format(dateType.DATE),
					endDate: this.$moment(this.dates[1]).format(dateType.DATE),
					domain: [this.value],
					extendDays: 7
				};
				this.searchDates = [reqData.startDate, reqData.endDate];
				const res = await getPE(reqData);
				if (res.status === statusCode.STATUS_OK) {
					localStorage.setItem('currency', this.currency);
					const result = res.result.res;
					this.getExportResult = JSON.parse(JSON.stringify(result));
					this.promoProfile = result.ctx_promo_profile;
					this.recapEarly = result.ctx_basic_recap_early[0];
					this.recap = result.ctx_basic_recap[0];
					this.termly = result.ctx_basic_termly;
					this.recapId = result.ctx_promo_id_recap;
					this.termlyId = result.ctx_promo_id_termly;
					this.recapType = result.ctx_promo_type_recap;
					this.termlyType = result.ctx_promo_termly_sum;

					this.termly.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
					this.termlyId.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
					this.termlyType.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));

					function fetch_columns(p_col_list, p_ctx_data){
						const min_date = p_ctx_data.map(e_1 => e_1.dim_ptt).reduce((a, b) => a < b ? a : b);
						const max_date = p_ctx_data.map(e_1 => e_1.dim_ptt).reduce((a, b) => a >= b ? a : b);
						const rpt_days = 1 + moment(max_date).diff(min_date, 'days');
						// let termly_array = Array.from(new Set(p_ctx_data.map(e_1 => e_1.dim_ptt))).map(() => []);
						let termly_array = [];
						for(let ix = 0; ix < rpt_days; ix++)
						  termly_array.push([]);

						p_ctx_data.forEach((e_1) => {
							const day_idx = moment(e_1.dim_ptt).diff(min_date, 'days');
							let num_list = p_col_list.forEach((e_2, ix_2) => {
								if(undefined == e_2.cond_match){
									termly_array[day_idx][ix_2] = parseFloat(e_1[e_2.col_name]) || 0;
									return;
								}
								if(e_1[e_2.cond_match.col_name] == e_2.cond_match.col_value){
									termly_array[day_idx][ix_2] = parseFloat(e_1[e_2.col_name]) || 0;
									return;
								}
								// termly_array[day_idx][ix_2] = 0;
							});
						});

						let termly_data = termly_array.map(e_1 => {
							let sum_value = (0 == e_1.length ? 0 : e_1.reduce((a, b) => a + b));
							let ratio_list = e_1.map(e_2 => e_2 / sum_value * 100);
							return {num_list : e_1, sum_value, ratio_list};
						});

						let value_data = p_col_list.map((e_1, ix_1) => {
							return termly_data.map(e_2 => e_2.num_list[ix_1]);
						});

						let ratio_data = p_col_list.map((e_1, ix_1) => {
							return termly_data.map(e_2 => e_2.ratio_list[ix_1]);
						});
						return {value_data, ratio_data};
					}

					const chart_data_list = [
						{ctx_data: this.termly, col_list: [
							{col_name: 'join_users', color: COLORS.pumpkin[5]},
							{col_name: 'no_promo_gm_users', color: COLORS.pumpkin[3]},
						]},
						{ctx_data: this.termly, col_list: [
							{col_name: 'in_promo_bet', color: COLORS.wisteria[5]},
							{col_name: 'no_promo_bet', color: COLORS.wisteria[3]},
						]},
						{ctx_data: this.termlyType, col_list: [
							{dst_name: 'type_0_bonus_amt', col_name: 'bonus_amt', cond_match: {col_name: 'dim_promo_type', col_value: 0}, color: COLORS.concrete[5]},
							{dst_name: 'type_1_bonus_amt', col_name: 'bonus_amt', cond_match: {col_name: 'dim_promo_type', col_value: 1}, color: COLORS.nephritis[5]},
							{dst_name: 'type_2_bonus_amt', col_name: 'bonus_amt', cond_match: {col_name: 'dim_promo_type', col_value: 2}, color: COLORS.sunflower[5]},
							{dst_name: 'type_3_bonus_amt', col_name: 'bonus_amt', cond_match: {col_name: 'dim_promo_type', col_value: 3}, color: COLORS.belize_hole[5]},
						]},
					];

					let ix_1 = 0;
					for(const key_1 in this.chart_options){
						const col_list = chart_data_list[ix_1].col_list;
						const ctx_data = chart_data_list[ix_1].ctx_data;
						const col_data = fetch_columns(col_list, ctx_data);
						trend_switchable_render(
							this.chart_options[key_1],
							{
								names: col_list.map(e_2 => {
									if(e_2.dst_name)
										return this.$t(`metric.${e_2.dst_name}`);
									else
										return this.$t(`metric.${e_2.col_name}`);
								}),
								colors: col_list.map(e_2 => e_2.color),
							},
							Array.from(new Set(ctx_data.map(e_2 => e_2.dim_ptt))),
							col_data.value_data,
							col_data.ratio_data,
						);
						ix_1++;
					}

					this.GUPieChart(this.recap);
					this.PTOPieChart(this.recap);
					this.NWBarChart(this.recap);
					this.NWLineChart(this.termly);
					this.BonusBarChart(this.recapType);
					this.CountPieChart(this.recapId);
					this.CountLineChart(this.termlyId);
					this.cardDisplay(this.recap, this.recapEarly);
				}
			} catch (e) {
				errorLogger(e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
			this.show = !this.show;
			this.loading = !this.loading;
		},
		async cardDisplay(res, earlyRes) {
			[
				{col_name: 'promo_count', card_opt: {note: 'mainData'}},
				{col_name: 'avg_bonus_amt', card_opt: {is_negative_growth: true}},
				{col_name: 'avg_type_1_bonus_amt', card_opt: {is_negative_growth: true}},
				{col_name: 'avg_type_2_bonus_amt', card_opt: {is_negative_growth: true}},
				{col_name: 'avg_type_3_bonus_amt', card_opt: {is_negative_growth: true}},
				{col_name: 'avg_type_0_bonus_amt', card_opt: {is_negative_growth: true}},
				{col_name: 'avg_no_promo_gm_users', card_opt: {note: 'people'}},
				{col_name: 'avg_join_users', card_opt: {note: 'people'}},
				{col_name: 'avg_join_count', card_opt: {note: 'people'}},
				{col_name: 'avg_done_count', card_opt: {note: 'people'}},
				{col_name: 'avg_no_promo_bet'},
				{col_name: 'avg_in_promo_bet'},
			].forEach(e_1 => {
				this.cardData[e_1.col_name] = chart_option_helper.build_card(e_1.col_name, res, earlyRes, {}, e_1.card_opt);
			});
		},
		async GUPieChart(res) {
			if(!res)
				return;

			let col_list = [
				{col_show: this.$t('metric.join_users'), col_name: 'avg_join_users', color: COLORS.pumpkin[5]},
				{col_show: this.$t('metric.no_promo_gm_users'), col_name: 'avg_no_promo_gm_users', color: COLORS.pumpkin[3]},
			];

			this.pieOption1 = chart_option_helper.get_pie_opt({
				title: {text: this.$t('charts.proGUSummary')},
				legend: {data: col_list.map(e_1 => e_1.col_show)},
				color: col_list.map(e_1 => e_1.color),
				graphic: {style: {text: this.$t('charts.avgDAU') + '\n' + roundOffFilter(res.avg_gm_users)}},
				series: [{
					data: col_list.map(e_1 => {
						return {
							name: e_1.col_show,
							value: res[e_1.col_name],
						};
					})
				}],
				tooltip: {formatter: (params) =>
					`${params.marker}${params.name}: ${roundOffFilter(params.value)} (${rdPercent(params.value / res.avg_gm_users)})`
				},
			});
		},
		async PTOPieChart(res) {
			if(!res)
				return;

			let col_list = [
				{col_show: this.$t('metric.in_promo_bet'), col_name: 'avg_in_promo_bet', color: COLORS.wisteria[5]},
				{col_show: this.$t('metric.no_promo_bet'), col_name: 'avg_no_promo_bet', color: COLORS.wisteria[3]},
			];

			this.pieOption2 = chart_option_helper.get_pie_opt({
				title: {text: this.$t('charts.proTSummary')},
				legend: {data: col_list.map(e_1 => e_1.col_show)},
				color: col_list.map(e_1 => e_1.color),
				graphic: {style: {text: this.$t('charts.avgDailyBet') + '\n' + roundOffFilter(res.avg_apv_bet)}},
				series: [{
					data: col_list.map(e_1 => {
						return {
							name: e_1.col_show,
							value: res[e_1.col_name],
						};
					})
				}],
				tooltip: {formatter: (params) =>
					`${params.marker}${params.name}: ${roundOffFilter(params.value)} (${rdPercent(params.value / res.avg_apv_bet)})`
				},
			});
		},
		async NWBarChart(res) {
			if (res) {
				const xAxisData = [this.$t('charts.avgNetWin'), this.$t('charts.inPromoNetWin'), this.$t('charts.noPromoNetWin')];
				const data = [res.avg_house_net_win, res.avg_in_promo_house_net_win, res.avg_no_promo_house_net_win];
				this.barOption1 = norBar({
					color: [COLORS.turquoise[7], COLORS.turquoise[5], COLORS.turquoise[3]],
					title: this.$t('charts.proNWSummary'),
					name: '',
					yAxis: [''],
					series: [],
				});
				for(let i = 0; i < xAxisData.length; i += 1) {
					this.barOption1.series.push({
						name: xAxisData[i],
						type: 'bar',
						stack: xAxisData[i],
						label: {
							show: true,
							position: 'inside',
							color: '#FFF',
							formatter: (params) => {
								return `${xAxisData[params.componentIndex]}: ${roundOffFilter(data[params.componentIndex])}`;
							}
						},
						data: [data[i]]
					});
				}
				// this.barOption1.series[1].label.position = 'inside';
				this.barOption1.title.textStyle.fontSize = 20;
				this.barOption1.grid.right = '15%';
				this.barOption1.xAxis.show = false;
				this.barOption1.yAxis[0].position = 'right';
				this.barOption1.tooltip.show = false;
			}
		},
		async NWLineChart(res) {
			if (!res)
				return;

			const col_list = [
				{col_show: this.$t('metric.in_promo_house_net_win'), col_name: 'in_promo_house_net_win', color: COLORS.turquoise[5]},
				{col_show: this.$t('metric.no_promo_house_net_win'), col_name: 'no_promo_house_net_win', color: COLORS.turquoise[3]},
			];

			this.lineOption3 = chart_option_helper.gen_line_opt({
				// title: {text: this.$t('charts.proNWDaily')},
				legend: {data: col_list.map(e_1 => e_1.col_show)},
				series: col_list.map(e_1 => {
					return {
						type: 'line',
						name: e_1.col_show,
						color: e_1.color,
					};
				}),
				dataset: {
					dimensions: ['dim_ptt'].concat(col_list.map(e_1 => e_1.col_name)),
					source: res,
				},
			});
		},
		async BonusBarChart(res) {
			if (res) {
				const xAxisData = Object.values(promoType);
				const data = [];
				this.barOption2 = norBar({
					color: [COLORS.concrete[5], COLORS.nephritis[5], COLORS.sunflower[5], COLORS.belize_hole[5]],
					title: this.$t('charts.proTypeSummary'),
					name: '',
					yAxis: [''],
					series: [],
				});
				for(let i of xAxisData) {
					data.push(0); // 預設空資料
				}
				for(let j of res) {
					data[j.dim_promo_type] = j.avg_bonus_amt; // 根據順序給資料
				}
				for(let i = 0; i < xAxisData.length; i += 1) {
					this.barOption2.series.push({
						name: xAxisData[i],
						type: 'bar',
						stack: xAxisData[i],
						label: {
							show: true,
							position: 'inside',
							color: '#FFF',
							formatter: (params) => {
								return `${xAxisData[params.componentIndex]}: ${roundOffFilter(data[params.componentIndex])}`;
							}
						},
						data: [data[i]]
					});
				}
				this.barOption2.title.textStyle.fontSize = 20;
				this.barOption2.series[0].label.position = 'right';
				this.barOption2.grid.right = '15%';
				this.barOption2.xAxis.show = false;
				this.barOption2.yAxis[0].position = 'right';
				this.barOption2.tooltip.show = false;
			}
		},
		async CountPieChart(res) {
			if (res) {
				res.sort((a, b) => (a.avg_join_count < b.avg_join_count ? 1 : -1));
				const legendData = [], tmp = [];
				for(let i of res) {
					tmp.push({
						value: i.avg_join_count,
						name: i.dim_promo_id
					});
					legendData.push(i.dim_promo_id);
				}
				// for(let i = 0; i < 10; i += 1) {
				// 	this.topTen.push(legendData[i].toString());
				// }
				this.pieOption5 = norPie();
				// this.pieOption1.color = [COLORS.wisteria[5], COLORS.wisteria[3]];
				this.pieOption5.title.text = this.$t('charts.promoCountSummary');
				this.pieOption5.title.textStyle.fontSize = 20;
				this.pieOption5.title.subtext = this.$t('common.interactable');
				this.pieOption5.legend.data = legendData;
				this.pieOption5.legend.show = false;
				this.pieOption5.graphic.style.text = this.$t('charts.avgPromoJoinCount') + '\n' + roundOffFilter(this.recap.avg_join_count);
				this.pieOption5.series[0].data = tmp;
				this.pieOption5.tooltip.formatter = (params) => {
					const finds = this.promoProfile.filter(function(act) {
						return act.dim_promo_id === parseInt(params.name);
					});
					const name = finds[0].promo_name;
					return `${params.marker}${params.name} - ${name}: ${roundOffFilter(params.value)} (${params.percent}%)`;
				};
			}
		},
		async CountLineChart(res) {
			let xAxisData = [], legendData = [], IDlegendData = [];
			const tmp1 = [], tmp2 = [], real = [];
			const tmpData = [];
			if (res) {
				for (let i of res) {
					IDlegendData.push(i.dim_promo_id);
				}
				// legendData = Array.from(new Set(legendData));
				legendData.push(this.$t('charts.joinCount'));
				// legendData.sort(function (a, b) { return a - b });
				for (let i of this.termly) {
					xAxisData.push(i.dim_ptt);
					tmpData.push(i.join_count);
				}
				xAxisData = Array.from(new Set(xAxisData));
				real.push({
					name: this.$t('charts.joinCount'),
					type: 'line',
					color: COLORS.silver[3],
					areaStyle: {},
					data: tmpData,
				});
				for (let i of IDlegendData) {
					tmp1.push([]);
				}
				for(let i of res) {
					for(let j = 0; j < IDlegendData.length; j += 1) {
						if(i.dim_promo_id === IDlegendData[j]) {
							tmp1[j].push(i);
						}
					}
				}
				for(let i = 0; i < tmp1.length; i += 1) {
					if(tmp1[i].length < xAxisData.length) {
						const tmp = [];
							const tmpArr = [];
							for (let k of tmp1[i]) {
								tmpArr.push(k.dim_ptt);
								tmp.push(k);
							}
							const kou = xAxisData.filter((e)=>{
								return tmpArr.indexOf(e) === -1
							})
							const first = tmp1[i][0];
							for(let i of kou) {
								tmp.push({
									dim_promo_id: first.dim_promo_id,
									promo_type_name: first.dim_promo_type,
									dim_ptt: i,
									join_count: 0,
									promo_name: first.promo_name
								})
							}
							tmp.sort((a, b) => (a.dim_ptt > b.dim_ptt ? 1 : -1));
							tmp2.push(tmp);
					} else {
						tmp2.push(tmp1[i]);
					}
				}
				// for(let i of tmp2) {
				// 	const tmp = [];
				// 	for(let j of i) {
				// 		tmp.push(j.join_count);
				// 	}
				// 	res.push({
				// 		name: i[0].dim_promo_id,
				// 		type: 'line',
				// 		// color: COLORS.wisteria[5],
				// 		data: tmp,
				// 	});
				// }
				this.allTermlyId = tmp2;
				this.lineOption6 = await line({
					// title: this.$t('charts.promoCountDaily'),
					legend: legendData,
					name: '',
					xAxis: xAxisData,
					series: real
				});
				// this.lineOption6.legend.type = 'scroll';
				this.lineOption6.xAxis.boundaryGap = false;
				// this.lineOption6.tooltip.formatter = (params) => {
				// 	const tmp = [];
				// 	let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(dateType.BRIEF_DATE)}<br/>`;
				// 	for (let i of this.topTen) {
				// 		for (let j of params) {
				// 			if(i === j.seriesName) {
				// 				tmp.push(j);
				// 			}
				// 		}
				// 	}
				// 	for(let i of tmp) {
				// 		const finds = res.filter(function(act) {
				// 			return act.dim_promo_id === parseInt(i.seriesName);
				// 		});
				// 		const name = finds[0].promo_name;
				// 		str += `${i.marker}${i.seriesName} - ${name}: ${roundOffFilter(i.value)}<br/>`;
				// 	}
				// 	return str;
				// };
				this.lineOption6.tooltip.formatter = (params) => {
					let str = `${this.$t('charts.date')}: ${this.$moment(params[0].name).format(dateType.BRIEF_DATE)}<br/>`;
					for (let i of params) {
						str += `${i.marker}${i.seriesName}: ${roundOffFilter(i.value)}<br/>`;
					}
					return str;
				};
			}
		},
		async getPromoID(params) {
			const promoId = parseInt(params.name);
			let count = [];
			let end = 7, name = '';
			if(this.$refs.lineOption6.option.series.length >= 1 ||
				this.$refs.lineOption6.option.series.length === 1) {
				if(this.$refs.lineOption6.option.series.length === 2) {
					this.$refs.lineOption6.option.legend.data.pop();
					this.$refs.lineOption6.option.series.pop();
				}
				for(let i of this.promoProfile) {
					if(promoId === i.dim_promo_id) {
						name = i.promo_name;
					}
				}
				for(let i of this.allTermlyId) {
					if(promoId === i[0].dim_promo_id) {
						for(let j of i) {
							count.push(j.join_count);
						}
						end = i.length;
					}
				}
				this.$refs.lineOption6.option.legend.data.push(promoId + ` ${name}`);
				this.$refs.lineOption6.option.series.push({
					name: promoId + ` ${name}`,
					type: 'line',
					color: params.color,
					areaStyle: {},
					data: count.slice(0, end),
				});
			}
		},
		allClean() {
			this.allTermlyId = [];
			this.recapId = [];
			this.real = [];
			this.tableData = [];
			this.cardData = {};
			for(let key_1 in this.chart_options){
				for(let key_2 in this.chart_options[key_1]){
					this.chart_options[key_1][key_2] = {};
				}
			}
			this.barOption1 = {};
			this.barOption2 = {};
			this.barOption3 = {};
			this.barOption4 = {};
			this.barOption5 = {};
			this.lineOption1 = {};
			this.lineOption2 = {};
			this.lineOption3 = {};
			this.lineOption4 = {};
			this.lineOption5 = {};
			this.lineOption6 = {};
			this.pieOption1 = {};
			this.pieOption2 = {};
			this.pieOption3 = {};
			this.pieOption4 = {};
			this.pieOption5 = {};
		},
		async exportXlsxFile() {
			try {
				let result = this.getExportResult;
				await exportXlsx('d', this.searchDates, result);
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
				let apiError = this.$t('error.apiError');
				this.errorDialogStatus(apiError);
			}
		},
		async getPercent() {},
		updateDomainValue(val, val2, val3) {
			this.value = val;
			this.domainMap = val2;
			this.currency = val3;
		},
		onResize() {
			this.windowSize = { x: window.innerWidth };
		},
		initData() {
			this.$emit('emitsavePath', this.$route.path);
		},
		closeDialog() {
			this.dialog = false;
		},
		showhelperText() {
			if (Object.keys(this.getExportResult).length === 0) {
				this.helperShow = true;
			}
		},
		errorDialogStatus(notifyMsg) {
			let errorTitle = this.$t('error.errorTitle');
			const errorMsg = {
				statusMessage: errorTitle,
				statusSrc: require('@/assets/image/error_icon.png'),
				statusText: notifyMsg,
			};
			let stringErrorMsg = JSON.stringify(errorMsg);
			this.setDialogShow(true);
			this.setStatusDialog(stringErrorMsg);
		},
		matchedIndexPin() {
			let operatorDefaultRouter = this.getOperatorDefaultRouter.split(',');
			if (this.$route.name === operatorDefaultRouter[0] && this.getOpenAutoSearch) {
				if (operatorDefaultRouter[1] === 'daily') {
					this.tab = 0;
				}
			}
		},
		goSearch() {
			$('body,html').animate({ scrollTop: 0 }, 800);
		},
		updateDate(val) {
			this.dates = val;
		},
	},
	watch: {
		tab: function () {
			$(function () {
				$('.fixBar').removeClass('dateFixBar');
				$(window).scroll(function () {
					let scrollVal = $(this).scrollTop();
					let div = $('.div-w');
					if (div.length) {
						let adscrtop = div.offset().top || { 'top': NaN }.top;
						if (scrollVal + 44 > adscrtop) {
							$('.fixBar').addClass('dateFixBar');
						} else {
							$('.fixBar').removeClass('dateFixBar');
						}
					}
				});
			});
		},
	},
	computed: {
		...mapGetters([
			'getDomain',
			'getDialogShow',
			'getStatusDialog',
			'getOperatorDefaultRouter',
			'getOpenAutoSearch',
		]),
	},
	created() {},
};
</script>