<template lang="pug">
    v-dialog(ref="dialog" v-model="modal" :return-value.sync="dates" persistent width="320px" overlay-opacity='0.9')
        template(v-slot:activator='{ on }')
            v-text-field(v-model="dateRangeText" prepend-icon="event" readonly v-on='on')
        v-card.pa-3
            v-radio-group(v-model='quickPick' v-if='quickPick !== "0"')
                v-radio(v-for='(item, index) in quickItems' :key='index' :label='`${item.label}`' :value='item.value' :disabled='item.disable' :ripple='false')
            v-date-picker.elevation-0(v-model="dates" range no-title full-width :min="minDate" v-if='quickPick === "0"'
            :allowed-dates='allowedDates' :locale='language')
                v-spacer
            v-row
                v-col.d-flex.justify-space-between
                    v-btn.date-btn.mr-3(color="grey darken-2" @click='resetDateRange' large) {{$t('common.cancel')}}
                    v-btn.date-btn.defult-btn-color(v-if='quickPick === "0"' @click="saveDates(dates)" :disabled='onOK' large) {{$t('common.ok')}}
</template>
<script>
import { mapActions } from 'vuex';
export default {
	props: ['tab'],
	data() {
		return  {
			onOK: false,
			modal: false,
			dates: [this.$moment().subtract(7, 'days').format('YYYY-MM-DD'), this.$moment().subtract(1, 'days').format('YYYY-MM-DD')],
			language: 'en',
			quickPick: '1',
			quickItems: [
				{
					label: this.$t('date.last7Days'),
					value: '1',
					disable: false
				},
				{
					label: this.$t('date.monthToDate'),
					value: '2',
					disable: false
				},
				{
					label: this.$t('date.previous1Month'),
					value: '3',
					disable: false
				},
				{
					label: this.$t('date.previous3Months'),
					value: '4',
					disable: false
				},
				{
					label: this.$t('date.previous1MonthToDate'),
					value: '5',
					disable: false
				},
				{
					label: this.$t('date.previous3MonthsToDate'),
					value: '6',
					disable: false
				},
				// {
				// 	label: this.$t('date.yearToDate'),
				// 	value: '7',
				// 	disable: false
				// },
				{
					label: this.$t('date.custom'),
					value: '0',
					disable: false
				}
			],
		};
        
	},
	mounted(){
		this.setOpenAutoSearch(false);
		this.$emit('emitupdateDate',this.dates);
		this.language = localStorage.getItem('language');
		this.handleDate();
	},
	computed: {
		dateRangeText() {
			return this.dates.join(' ~ ');
		},
		minDate(){
			return this.$moment().subtract(24, 'months').format('YYYY-MM-01');
		}
	},
	watch: {
		tab:function(val){
			let vm = this;
			if(val === 0){
				vm.$emit('emitupdateDate',vm.dates);
			}
		},
		quickPick: function(val){
			if(val === '1'){
				// 前七天
				this.dates=[this.$moment().subtract(7, 'days').format('YYYY-MM-DD'),
					this.$moment().subtract(1, 'days').format('YYYY-MM-DD')];
				this.saveDates(this.dates);
			} else if(val === '2'){
				// 本月迄今
				this.dates=[this.$moment().startOf('month').format('YYYY-MM-DD'),
					this.$moment().subtract(1, 'days').format('YYYY-MM-DD')];
				this.saveDates(this.dates);
			} else if(val === '3'){
				// 前一個月
				this.dates=[this.$moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD'),
					this.$moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD')];
				this.saveDates(this.dates);
			} else if(val === '4'){
				// 前三個月
				this.dates=[this.$moment().subtract(3,'months').startOf('month').format('YYYY-MM-DD'),
					this.$moment().subtract(1,'months').endOf('month').format('YYYY-MM-DD')];
				this.saveDates(this.dates);
			} else if(val === '5'){
				// 前一個月迄今
				this.dates=[this.$moment().subtract(1,'months').startOf('month').format('YYYY-MM-DD'),
					this.$moment().subtract(1, 'days').format('YYYY-MM-DD')];
				this.saveDates(this.dates);
			} else if(val === '6'){
				// 前三個月迄今
				this.dates=[this.$moment().subtract(3,'months').startOf('month').format('YYYY-MM-DD'),
					this.$moment().subtract(1, 'days').format('YYYY-MM-DD')];
				this.saveDates(this.dates);
			}
			//  else if(val === '7'){
			// 	// 今年迄今
			// 	this.dates=[this.$moment().subtract(this.$moment().month(),'months').startOf('month').format('YYYY-MM-DD'),
			// 		this.$moment().subtract(1, 'days').format('YYYY-MM-DD')];
			// 	this.saveDates(this.dates);
			// }
		},
		dates: function(value){
			if(value[1]){
				this.onOK = false;
			}else{
				this.onOK = true;
			}
			if(value[0]>value[1]){
				const tmp = value[1];
				value[1] = value[0];
				value[0] = tmp;
			}			 
		},
	},
	methods: {
		...mapActions(['setOpenAutoSearch']),
		allowedDates(val) {
			return val < this.$moment().format('YYYY-MM-DD');
		},
		resetDateRange(){
			this.modal = false;
			if(this.quickPick === '0'){
				this.quickPick = '1';
			}
		},
		saveDates(dates){
			this.$refs.dialog.save(dates);
			this.$emit('emitupdateDate',dates);
		},
		handleDate(){
			if(this.$moment().startOf('month').format('YYYY-MM-DD') === this.$moment().format('YYYY-MM-DD')){
				// 本月迄今選項，若本日為本月第一天則不能查
				this.quickItems[1].disable = true;
			}
			if(this.$moment().subtract(this.$moment().month(),'months').startOf('month').format('YYYY-MM-DD')
			=== this.$moment().format('YYYY-MM-DD')){
				// 今年迄今選項，若本日為本年第一天則不能查
				this.quickItems[6].disable = true;
			}
		}
	}
};
</script>