import dateType from '@/assets/constant/dateType';
import moment from 'moment';
import { fetchColumns, computeRatio, roundOffFilter } from '@/util/format';
import { bar, line, barPercent } from '@/assets/echarts/ocmsCommon/house/reg';

export function render(p_chart_option, p_ctx_group, p_xaxis_data, p_value_data, p_ratio_data){
  const legend_data = p_ctx_group.names;
  const common_opt = {
    name: '',
    title: '',
    color: p_ctx_group.colors,
    legend: legend_data,
    xAxis: p_xaxis_data,
  };

  if(undefined != p_chart_option.opt_line){
    p_chart_option.opt_line = line({...common_opt, series: legend_data.map((elem, idx) => {return {
      type: 'line',
      name: elem,
      data: p_value_data[idx],
    }})});
  }

  if(undefined != p_chart_option.opt_area){
    p_chart_option.opt_area = line({...common_opt, series: legend_data.map((elem, idx) => {return {
      type: 'line',
      stack: 'total',
      areaStyle: {},
      name: elem,
      data: p_value_data[idx],
    }})});
    p_chart_option.opt_area.xAxis.boundaryGap = false;
  }

  if(undefined != p_chart_option.opt_stack){
    p_chart_option.opt_stack = barPercent({...common_opt, series: legend_data.map((elem, idx) => {return {
      type: 'bar',
      stack: 'all',
      name: elem,
      data: p_ratio_data[idx],
    }})});

    // p_chart_option.opt_stack.tooltip.valueFormatter = (value) => {
    //   return '$' + value.toFixed(2);
    // };
    p_chart_option.opt_stack.tooltip.formatter = (params) => {
      let te = `${params[0].name}<br/>`;
      for(let elem of params)
        te += `${elem.marker}${elem.seriesName}: ${elem.value.toFixed(1)}%<br/>`;
      return te;
    };
  }
};
