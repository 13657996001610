<template lang='pug'>
div
	v-col.px-0.py-1(
		cols='12',
		v-if='this.cardData'
	)
		v-tooltip(v-if='items[0].tooltip' bottom, color='blue darken-3')
			template(v-slot:activator='{ on, attrs }')
				.px-2.memberCard(v-for='(item, key) in items' v-on='on' v-bind='attrs')
					.memberCardTitle.pt-3(v-if='item.title.length < 28') {{ item.title }}
					.memberCardTitleSmall.pt-3(v-else) {{ item.title }}
					.text-center.memberCardContent.ml-12.mr-7(:class='{ valuetoGreen: valueToGreen, valuetoRed: valueToRed, textToSmall: textToSmall }') {{ item.content }}
					.memberCardRemark.pb-3.text-right(:class='{ markertoGreen: markToGreen, markertoRed: markToRed }') {{ item.marker }}
			span {{ items[0].toolContent || '' }}
			br
			span {{ items[0].toolMarker || '' }}
			br
			span {{ items[0].toolCon }}
			br
			span {{ items[0].toolMar }}
		v-tooltip(v-else)
			template(v-slot:activator='{ on, attrs }')
				.px-2.memberCard(v-for='(item, key) in items' v-on='on' v-bind='attrs')
					.memberCardTitle.pt-3(v-if='item.title.length < 28') {{ item.title }}
					.memberCardTitleSmall.pt-3(v-else) {{ item.title }}
					.text-center.memberCardContent.ml-12.mr-7(:class='{ valuetoGreen: valueToGreen, valuetoRed: valueToRed, textToSmall: textToSmall }') {{ item.content }}
					.memberCardRemark.pb-3.text-right(:class='{ markertoGreen: markToGreen, markertoRed: markToRed }') {{ item.marker }}
</template>
<script>
//v-if='this.mainTitle || this.subTitle || this.mainData || this.subData || this.note'
import _ from 'lodash';
import {
	commaFormatter,
	gapPercent,
	nFormatter,
	trans,
	transData,
	transfer,
	toThousandslsFilter,
	rateOfExchange,
	roundOff,
	roundOffFilter,
	rdPercent,
	roundDecimal,
	roundFilter,
	getNaN,
} from '@/util/format';

export default {
	name: 'Kanban',
	props: [
		'cardData',
		// cardData: {
		// 	mainTitle: Main Title
		// 	subTitle: Sub Title
		// 	mainData: Main Data
		// 	subData: Sub Data
		// 	tooltip: True
		// 	toolMainTitle: Tooltip Main Title
		// 	toolSubTitle: Tooltip Sub Title
		// 	toolMainData: Tooltip Main Data
		// 	toolSubData: Tooltip Sub Data
		// 	note: [people percent value]
		// }
		// example: {
		// 	mainTitle: 'Main Title',
		// 	subTitle: 'Sub Title',
		// 	mainData: 1000,
		// 	subData: 500,
		// 	tooltip: true,
		// 	toolMainTitle: 'Tooltip Main Title',
		// 	toolSubTitle: 'Tooltip Sub Title',
		// 	toolMainData: 300,
		// 	toolSubData: 200,
		// 	note: 'people'
		// },
	],
	data() {
		return {
			items: [],
			valueToGreen: false,
			valueToRed: false,
			markToGreen: false,
			markToRed: false,
			textToSmall: false,
		};
	},
	creat() { },
	methods: {
		display(val) {
			this.items = [];
			const obj = {};
			switch (val.note) {
				case 'mainData':
					obj.title = val.mainTitle;
					obj.content = `${roundOffFilter(val.mainData)}`;
					obj.marker = `${val.subTitle}: ${roundOffFilter(val.subData)}`;
					this.items.push(obj);
					// this.changeColor(roundOff(val.mainData - val.subData));
					break;
				case 'mainDataFullDisplay':
					obj.title = val.mainTitle;
					obj.content = `${roundOffFilter(val.mainData)} (${gapPercent( val.mainData / val.subData )})`;
					obj.marker = `${val.subTitle}: ${roundOffFilter(val.subData)}`;
					this.items.push(obj);
					this.changeColor(val.mainData - val.subData);
					break;
				case 'people':
					obj.title = val.mainTitle;
					obj.content = `${roundOffFilter(val.mainData)} (${gapPercent( val.mainData / val.subData )})`;
					obj.marker = `${val.subTitle}: ${roundOffFilter(val.subData)}`;
					if(val.tooltip) {
						obj.tooltip = val.tooltip;
						obj.toolContent = `${val.mainTitle} (${val.toolSubTitle}): ${roundOffFilter(val.mainData)}`;
						obj.toolMarker = `${val.mainTitle} (${val.subTitle}): ${roundOffFilter(val.subData)}`;
						obj.toolCon = `${val.toolMainTitle} (${val.toolSubTitle}): ${roundOffFilter(val.toolMainData)}`;
						obj.toolMar = `${val.toolMainTitle} (${val.subTitle}): ${roundOffFilter(val.toolSubData)}`;

						// obj.tooltip = val.tooltip;
						// obj.toolCon = `${val.toolMainTitle}: ${roundOffFilter(val.toolMainData)}`;
						// obj.toolMar = `${val.toolSubTitle}: ${roundOffFilter(val.toolSubData)}`;
					}
					this.items.push(obj);
					this.changeColor(val.mainData - val.subData);
					break;
				case 'percent':
					obj.title = val.mainTitle;
					if(!_.isFinite(val.mainData / val.subData)) { //判斷皆為0
						obj.content = `${rdPercent(val.mainData)} (${rdPercent( 0 )})`;
					} else {
						obj.content = `${rdPercent(val.mainData)} (${gapPercent( val.mainData / val.subData )})`;
					}
					obj.marker = `${val.subTitle}: ${rdPercent(val.subData)}`;
					if(val.tooltip) {
						obj.tooltip = val.tooltip;
						obj.toolCon = `${val.toolMainTitle}: ${rdPercent(val.toolMainData)}`;
						obj.toolMar = `${val.toolSubTitle}: ${rdPercent(val.toolSubData)}`;
					}
					this.items.push(obj);
					this.changeColor(val.mainData * 1000000 - val.subData * 1000000);
					break;
				default:  // value
					obj.title = val.mainTitle;
					obj.content = `${nFormatter(val.mainData, 1)} (${gapPercent( val.mainData / val.subData )})`;
					obj.marker = `${val.subTitle}: ${nFormatter(val.subData, 1)}`;
					if(val.tooltip) {
						obj.tooltip = val.tooltip;
						obj.toolContent = `${val.mainTitle} (${val.toolSubTitle}): ${roundOffFilter(val.mainData)}`;
						obj.toolMarker = `${val.mainTitle} (${val.subTitle}): ${roundOffFilter(val.subData)}`;
						obj.toolCon = `${val.toolMainTitle} (${val.toolSubTitle}): ${roundOffFilter(val.toolMainData)}`;
						obj.toolMar = `${val.toolMainTitle} (${val.subTitle}): ${roundOffFilter(val.toolSubData)}`;
					}
					this.items.push(obj);
					this.changeColor(val.mainData - val.subData);
					if(val.mainData === 0 && val.subData === 0) this.changeColor(0);
			}
		},
		changeColor(val) {
			if(this.cardData.is_negative_growth)
				val = val * -1;
			// const regex = /^[0-9]*[1-9][0-9]*$/; // 檢測正整數
			// if (regex.test(val)) {
			if (val > 0) {
				this.valueToGreen = true;
				this.valueToRed = false;
			} else {
				this.valueToGreen = false;
				this.valueToRed = true;
			}
			if (Object.is(val, 0) || val === 0 || isNaN(val)) {
				this.valueToGreen = false;
				this.valueToRed = false;
			}
		},
	},
	watch: {
		cardData: {
			handler: function (val) {
				if (val) {
					this.display(val);
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>