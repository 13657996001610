<template lang='pug'>
v-row.rowUpper
	v-col.py-0(v-if='areaSwitch', cols='12', md='6', lg='4')
		v-row.input-wrap.mx-0
			v-col.pa-0.labal-bgc.text-center(cols='3')
				span.caption {{ $t("common.cmsList") }}
			v-col.pa-0(cols='9')
				ValidationProvider(rules='required', v-slot='{ errors }')
					v-select.memberSelect.cars-select(
						v-model='area',
						:items='areaList',
						required,
						:error-messages='errors',
						single-line,
						background-color='rgba(255, 255, 255, 0.1)',
						dense,
						solo,
						item-color='#fff',
						:menu-props='menuProps',
						return-object
					)
						template(v-slot:selection='{ item, index }')
							span(v-if='selectItemName(area, areaList, index)')
								span {{ item }}
	v-col.py-0(v-if='domainSwitch', cols='12', md='6', lg='4')
		v-row.input-wrap.mx-0
			v-col.pa-0.labal-bgc.text-center(cols='3')
				span.caption {{ $t("charts.domain") }}
			v-col.pa-0(cols='9')
				ValidationProvider(rules='required', v-slot='{ errors }')
					v-select.memberSelect.cars-select(
						v-model='value',
						:items='domain',
						required,
						:error-messages='errors',
						single-line,
						background-color='rgba(255, 255, 255, 0.1)',
						dense,
						solo,
						item-color='#fff',
						:menu-props='menuProps'
					)
						template(v-slot:selection='{ item, index }')
							span.text-center(v-if='selectItemName(value, domain, index)')
								span {{ item }}
	v-col.py-0(v-if='currencyStatus', cols='12', md='6', lg='4')
		v-row.input-wrap.mx-0
			v-col.pa-0.labal-bgc.text-center(cols='3')
				span.caption {{ $t("common.curencyList") }}
			v-col.pa-0(cols='9')
				ValidationProvider(rules='required', v-slot='{ errors }')
					v-select.cars-select(
						ref='currency',
						v-model='currency',
						:items='rateList',
						required,
						:error-messages='errors',
						single-line,
						background-color='rgba(255, 255, 255, 0.1)',
						dense,
						solo,
						item-color='#fff',
						:menu-props='menuProps'
					)
						template(v-slot:selection='{ item, index }')
							v-spacer(v-if='index === 0')
							span(v-if='index === 0')
								span {{ item }}
</template>
<script>
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';
import { setCookie, getCookie } from '@/util/cookies';
import cookieType from '@/assets/constant/cookieType';

export default {
	components: {
		ValidationProvider,
	},
	props: ['currencyStatus', 'setArea', 'setDomain'],
	data() {
		return {
			areaSwitch: true,
			domainSwitch: true,
			area: [],
			areaList: [],
			value: [],
			domain: [],
			turnOff: false,
			currency: localStorage.getItem('currency'),
			rateList: [],
			menuProps: {
				offsetY: true,
			},
		};
	},
	methods: {
		selectItemName(data, items, index) {
			return index === 0 && data;
		},
	},
	computed: {
		...mapGetters(['getDomain', 'getCurrency', 'getCurrencyList']),
	},
	watch: {
		setArea: {
			handler: function (val) {
				if (val) {
					this.area = val;
					let domainValue = this.getDomain['OCMS'][val];
					let tmp = Object.keys(domainValue);
					this.domain = tmp;
					for(let i of this.domain) {
						if(i.includes(this.setDomain)) {
							this.value = i;
						}
					}
				}
			},
			deep: true,
			immediate: true,
		},
		currency: function () {
			let realDomain = '';
			if (this.value.includes('-')) {
				const tmp = this.value.split('-');
				realDomain = tmp[0];
			} else {
				realDomain = this.value;
			}
			setCookie(cookieType.NAME.CURRENCY, this.currency, cookieType.EXPIRE.NEVER);
			this.$emit('emitDomainValue', realDomain, this.area, this.currency);
		},
		value: function () {
			let realDomain = '';
			if (this.value.includes('-')) {
				const tmp = this.value.split('-');
				realDomain = tmp[0];
			} else {
				realDomain = this.value;
			}
			this.$emit('emitDomainValue', realDomain, this.area, this.currency);
		},
		domain: function (value) {
			const arr = JSON.parse(JSON.stringify(value));
			if (arr.length === 0) {
				this.turnOff = true;
			} else {
				this.turnOff = false;
			}
		},
		area: function (value) {
			let domainValue = this.getDomain['OCMS'][value];
			let tmp = Object.keys(domainValue);
			this.domain = tmp;
			if (this.domain.length <= 1) {
				this.domainSwitch = false;
				const tmp = this.domain[0].split('-');
				this.value = tmp[0];
			} else {
				this.domainSwitch = true;
			}
		},
	},
	created() {
		this.areaList = Object.keys(this.getDomain['OCMS']);
		this.areaList.sort();
		if (this.areaList.length <= 1) {
			this.areaSwitch = false;
			this.area = this.areaList[0];
		}
		this.rateList = this.getCurrencyList;
		if (!this.rateList.includes(getCookie(cookieType.NAME.CURRENCY))) {
			setCookie(cookieType.NAME.CURRENCY, "THB", cookieType.EXPIRE.NEVER);
		}
		this.currency = getCookie(cookieType.NAME.CURRENCY);
		this.$emit('emitDomainValue', this.value, this.area, this.currency);
	},
};
</script>