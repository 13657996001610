const COLORS = {
	//gray
	lighter_gray: '#e8e4e1',
	light_gray: '#BEBEBE',
	gray: '#9D9D9D',
	dark_blue_gray: '#697089',
	dark_gray: '#6C6C6C',
	//pink orange
	light_orange: '#f9c49a',
	light_pink: '#FFC0CB',
	bright_pink: '#ff9595',
	orange_red: '#EA8B5A',
	lighter_pink: '#FFCDD2',
	hotpink: '#ff69b4',
	//yellow
	yellow: '#FFDC35',
	light_yellow_green: '#E1E100',
	mustard: '#FFDB5C',
	dark_mustard: '#F3C83D',
	orange_yellow: '#FFC964',
	//blue
	blue: '#4BA7DB',
	light_blue: '#42A5F5',
	dark_blue: '#1b6ca8',
	darker_blue: '#4371B2',
	//brown
	skin: 'fce8d5',
	Cameo: '#D8B89E',
	brown: '#AF694A',
	dark_brown: '#7c3c21',
	//Indigo purple
	indigo: '#525DE5',
	darker_Indigo: '#405EC1',
	dark_gray_indigo: '#57587F',
	light_purple: '#CE93D8',
	light_purple_red: '#C76892',
	//green
	light_green: '#9FE6B8',
	bright_green: '#3AC6B1',
	lighter_green: '#C8E6C9',
	dark_grass_green: '#0b6300',
	grass_green: '#80bdab',
	dark_green: '#18ABB1',
	darker_green: '#598E6B',
	water_blue_green: '#67E0E3',
	grass_blue_green: '#0a97b0',
	dark_water_blue_green: '#32C5E9',
	// POMEGRANATE (石榴)
	pomegranate: ['#F9EBEA', '#F2D7D5', '#E6B0AA', '#D98880', '#CD6155', '#C0392B', '#A93226', '#922B21', '#7B241C', '#641E16'],
	// ALIZARIN (茜素)
	alizarin: ['#FDEDEC', '#FADBD8', '#F5B7B1', '#F1948A', '#EC7063', '#E74C3C', '#CB4335', '#B03A2E', '#943126', '#78281F'],
	// AMETHYST (紫水晶)
	amethyst: ['#F5EEF8', '#EBDEF0', '#D7BDE2', '#C39BD3', '#AF7AC5', '#9B59B6', '#884EA0', '#76448A', '#633974', '#512E5F'],
	// WISTERIA (紫藤)
	wisteria: ['#F4ECF7', '#E8DAEF', '#D2B4DE', '#BB8FCE', '#A569BD', '#8E44AD', '#7D3C98', '#6C3483', '#5B2C6F', '#4A235A'],
	// BELIZE HOLE (藍洞)
	belize_hole: ['#EAF2F8', '#D4E6F1', '#A9CCE3', '#7FB3D5', '#5499C7', '#2980B9', '#2471A3', '#1F618D', '#1A5276', '#154360'],
	// PETER RIVER (彼得河)
	peter_river: ['#EBF5FB', '#D6EAF8', '#AED6F1', '#85C1E9', '#5DADE2', '#3498DB', '#2E86C1', '#2874A6', '#21618C', '#1B4F72'],
	// TURQUOISE (綠松石)
	turquoise: ['#E8F8F5', '#D1F2EB', '#A3E4D7', '#76D7C4', '#48C9B0', '#1ABC9C', '#17A589', '#17A589', '#117864', '#0E6251'],
	// GREEN SEA (綠海)
	green_sea: ['#E8F6F3', '#D0ECE7', '#A2D9CE', '#73C6B6', '#45B39D', '#16A085', '#138D75', '#117A65', '#0E6655', '#0B5345'],
	// NEPHRITIS (奈飛利)
	nephritis: ['#E9F7EF', '#D4EFDF', '#A9DFBF', '#7DCEA0', '#52BE80', '#27AE60', '#229954', '#1E8449', '#196F3D', '#145A32'],
	// EMERALD (祖母綠)
	emerald: ['#EAFAF1', '#D5F5E3', '#ABEBC6', '#82E0AA', '#58D68D', '#2ECC71', '#28B463', '#239B56', '#1D8348', '#186A3B'],
	// SUNFLOWER (向日葵)
	sunflower: ['#FEF9E7', '#FCF3CF', '#F9E79F', '#F7DC6F', '#F4D03F', '#F1C40F', '#D4AC0D', '#B7950B', '#9A7D0A', '#7D6608'],
	// ORANGE (橘子)
	orange: ['#FEF5E7', '#FDEBD0', '#FAD7A0', '#F8C471', '#F5B041', '#F39C12', '#D68910', '#B9770E', '#9C640C', '#7E5109'],
	// CARROT (胡蘿蔔)
	carrot: ['#FDF2E9', '#FAE5D3', '#F5CBA7', '#F0B27A', '#EB984E', '#E67E22', '#CA6F1E', '#AF601A', '#935116', '#784212'],
	// PUMPKIN (南瓜)
	pumpkin: ['#FBEEE6', '#F6DDCC', '#EDBB99', '#E59866', '#DC7633', '#D35400', '#BA4A00', '#A04000', '#873600', '#6E2C00'],
	// CLOUDS (雲朵)
	clouds: ['#FDFEFE', '#FBFCFC', '#F7F9F9', '#F4F6F7', '#F0F3F4', '#ECF0F1', '#D0D3D4', '#B3B6B7', '#979A9A', '#7B7D7D'],
	// SILVER (白銀)
	silver: ['#F8F9F9', '#F2F3F4', '#E5E7E9', '#D7DBDD', '#CACFD2', '#BDC3C7', '#A6ACAF', '#909497', '#797D7F', '#626567'],
	// CONCRETE (混凝土)
	concrete: ['#F4F6F6', '#EAEDED', '#D5DBDB', '#BFC9CA', '#AAB7B8', '#95A5A6', '#839192', '#717D7E', '#5F6A6A', '#4D5656'],
	// ASBESTOS (石棉)
	asbestos: ['#F2F4F4', '#E5E8E8', '#CCD1D1', '#B2BABB', '#99A3A4', '#7F8C8D', '#707B7C', '#616A6B', '#515A5A', '#424949'],
	// WET ASPHALT (濕瀝青)
	wet_asphalt: ['#EBEDEF', '#D6DBDF', '#AEB6BF', '#85929E', '#5D6D7E', '#34495E', '#2E4053', '#283747', '#212F3C', '#1B2631'],
	// MIDNIGHT BLUE (午夜藍)
	midnight_blue: ['#EAECEE', '#D5D8DC', '#ABB2B9', '#808B96', '#566573', '#2C3E50', '#273746', '#212F3D', '#1C2833', '#17202A'],
	gen_hue_colors: (color_num) => {
		const colors = [];
		const tmp = [];
		const hueStep = 300 / color_num; // 減少紅色區域
		const startingHue = 30;	// 開始色相值，避開紅色範圍
		const saturation = 70;
		const lightness = 50;
		for (let i = 0; i < color_num; i++) {
			const hue = (startingHue + i * hueStep) % 360;
			const color = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
			tmp.push(color);
		}
		for (let i = 0; i < color_num; i++) {
			if (i % 2)
				colors.push(tmp.pop())
			else
				colors.push(tmp.shift())
		}
		return colors;
	}
};

export default COLORS;
